import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import SelectField from '../../../../../../forms/selectField/SelectField';
import TextField from '../../../../../../forms/textField/TextField';
import TextAreaField from '../../../../../../forms/textAreaField/TextAreaField';
import { ModalRow } from '../LeadModalActionsTab.styles';
import useGetCurrencySymbol from '../../../../../../../hooks/useGetCurrencySymbol';
import { CategoryList } from './CategoryList';
import { useMisconductCategories } from './useMisconductCategories';
import { TextAreFieldWrapper } from './GuestMisconductModal.styles';
import { useDamageTypeOptions } from './useDamageTypes';

export const GuestMisconductFormBody = ({ currency }) => {
  const categoryValue = useWatch({ name: 'category' });
  const { t } = useTranslation();
  const { isPropertyDamage } = useMisconductCategories();
  const selectOptions = useDamageTypeOptions();
  const getCurrencySymbol = useGetCurrencySymbol();
  return (
    <>
      <ModalRow>
        <CategoryList />
      </ModalRow>
      {isPropertyDamage(categoryValue) && (
        <ModalRow>
          <SelectField
            colSmInput={7}
            colSmLabel={4}
            name="damageType"
            label={t(
              'componentLead.modal.actionsTab.guestMisconduct.fieldTypeOfDamage',
            )}
            options={selectOptions}
            required
          />
        </ModalRow>
      )}

      {isPropertyDamage(categoryValue) && (
        <ModalRow>
          <TextField
            colSmInput={7}
            colSmLabel={4}
            name="damageCost"
            label={t(
              'componentLead.modal.actionsTab.guestMisconduct.fieldCostOfDamage',
            )}
            min={0}
            type="number"
            leftAddon={getCurrencySymbol(currency)}
            required
          />
        </ModalRow>
      )}

      <ModalRow>
        <TextAreFieldWrapper>
          <TextAreaField
            name="detailsText"
            label={t(
              'componentLead.modal.actionsTab.guestMisconduct.fieldDetails',
            )}
            colSmInput={7}
            colSmLabel={4}
            rows={3}
            required
          />
        </TextAreFieldWrapper>
      </ModalRow>
    </>
  );
};
