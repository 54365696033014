import { useTranslation } from 'react-i18next';
import {
  MisconductCategory,
  MisconductSubCategory,
} from '../../../../../../../models/channelactions/GuestMisconductBody';

// TODO: we may want to load this from BE.
const categories: MisconductCategory[] = [
  {
    id: 1,
    title: 'Payment issue',
    subcategories: [
      {
        id: 110,
        title: 'Didn’t pay reservation amount',
      },
      {
        id: 111,
        title: 'Didn’t pay additional fees (eg. taxes, damage deposit)',
      },
      {
        id: 115,
        title: 'Other',
      },
    ],
  },
  {
    id: 7,
    title: 'Breaking house rules',
    subcategories: [
      {
        id: 170,
        title: 'Smoking',
      },
      {
        id: 171,
        title: 'Brought pets',
      },
      {
        id: 172,
        title: 'Threw a party',
      },
      {
        id: 173,
        title: 'Broke quiet hours',
      },
      {
        id: 174,
        title: 'Brought additional guests',
      },
      {
        id: 175,
        title: 'Breached check-in/check-out policy',
      },
      {
        id: 176,
        title: 'Other',
      },
    ],
  },
  {
    id: 0,
    title: 'Abusive behaviour',
    subcategories: [
      {
        id: 100,
        title: 'Verbal abuse',
      },
      {
        id: 101,
        title: 'Physical abuse',
      },
      {
        id: 102,
        title: 'Verbal/physical threats',
      },
      {
        id: 103,
        title: 'Disturbing guests/neighbours',
      },
      {
        id: 104,
        title: 'Other',
      },
    ],
  },
  {
    id: 2,
    title: 'Left property dirty',
    subcategories: [
      {
        id: 120,
        title: 'Stained linens',
      },
      {
        id: 121,
        title: 'Stains on carpet/walls/furniture',
      },
      {
        id: 122,
        title: 'Excessive rubbish',
      },
      {
        id: 123,
        title: 'Other',
      },
    ],
  },
  {
    id: 9,
    title: 'Property damage',
    subcategories: [],
  },
  {
    id: 5,
    title: 'Illegal activities',
    subcategories: [
      {
        id: 150,
        title: 'Drug use/drug dealing',
      },
      {
        id: 151,
        title: 'Not complying with local law',
      },
      {
        id: 160,
        title: 'Theft',
      },
      {
        id: 152,
        title: 'Other',
      },
    ],
  },
];

function isPropertyDamage(categoryId: string | number): boolean {
  return categoryId === '9' || categoryId === 9;
}

function isCategoryHasSupCategory(categoryId: string | number): boolean {
  const categoryAsNumber = Number(categoryId);
  const found = categories.find((it) => it.id === categoryAsNumber);
  return found?.subcategories?.length > 0;
}

export const useMisconductCategories = () => {
  const { t, i18n } = useTranslation();
  const translateCategoryName = (category: MisconductCategory): string => {
    const key = `componentLead.modal.actionsTab.guestMisconduct.categories.${category.title}`;
    // @ts-ignore
    return i18n.exists(key) ? t(key) : category.title;
  };

  const translateSubCategoryName = (
    subCategory: MisconductSubCategory,
  ): string => {
    const key = `componentLead.modal.actionsTab.guestMisconduct.subCategories.${subCategory.title}`;
    // @ts-ignore
    return i18n.exists(key) ? t(key) : subCategory.title;
  };

  return {
    categories,
    isPropertyDamage,
    isCategoryHasSupCategory,
    translateCategoryName,
    translateSubCategoryName,
  };
};
