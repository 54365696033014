import { Radio } from 'react-bootstrap';
import {
  FieldRendererProps,
  GenericFormFieldProps,
} from '../genericFormField/GenericFormField.types';
import GenericFormField from '../genericFormField/GenericFormField';

export interface RadioFieldProps
  extends Omit<GenericFormFieldProps, 'fieldRenderer'> {
  groupLabel: string;
}

export const RadioField = ({
  name,
  label,
  value,
  groupLabel,
  ...props
}: RadioFieldProps) => {
  const renderField = ({ field: { ref, onChange } }: FieldRendererProps) => {
    return (
      <Radio
        name={name}
        value={value}
        onChange={onChange}
        inputRef={ref}
        required={props.required}
      >
        {label}
      </Radio>
    );
  };
  return (
    <GenericFormField
      fieldRenderer={renderField}
      name={name}
      {...props}
      label={groupLabel || null}
    />
  );
};
