import LeadSelectOption from 'components/forms/domain/lead/LeadSelectOption';
import FormWithProvider from 'components/forms/form/Form';
import useAppModal from 'hooks/useAppModal';
import { LeadStatus } from 'models/Leads';
import { CalendarLead } from 'pages/stackedCalendar/Calendar.types';
import {
  Col,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  getFullName,
  getLeadCheckInDate,
  getLeadCheckOutDate,
} from 'utils/lead/leadUtils';
import * as Yup from 'yup';
import CustomSelectField from 'components/forms/customSelectField/CustomSelectField';
import Button from 'components/button/Button';
import useOpenBlockModal from 'components/modal/blockModal/useOpenBlockModal';
import { Property } from 'models/Properties';
import useOpenLeadModal from '../modal/useOpenLeadModal';
import { LeadsOverlapModalBody } from './LeadsOverlapModal.styles';

const schema = () => ({
  leadUid: Yup.string().required(),
});

export const modalId = 'leads-overlap-modal';

const LeadsOverlapModal = ({
  leads,
  allProperties,
}: {
  leads: CalendarLead[];
  allProperties: Property[];
}) => {
  const { t } = useTranslation();
  const { updateLeadModal } = useOpenLeadModal();
  const { updateBlockModal } = useOpenBlockModal({ allProperties });
  const { closeModal } = useAppModal();

  const handleCancel = () => {
    closeModal(modalId);
  };

  const handleSelect = ({ leadUid }) => {
    const lead = leads.find((l) => l.uid === leadUid);

    closeModal(modalId);

    if (lead.status === LeadStatus.BLOCKED) {
      updateBlockModal({
        blockUid: leadUid,
      });
    } else {
      updateLeadModal({
        leadUid,
      });
    }
  };

  const sortedLeads = leads.toSorted((a, b) => {
    // all the blocked leads should be at the end
    if (a.status === LeadStatus.BLOCKED && b.status !== LeadStatus.BLOCKED) {
      return 1;
    }
    if (a.status !== LeadStatus.BLOCKED && b.status === LeadStatus.BLOCKED) {
      return -1;
    }
    return 0;
  });

  const options = sortedLeads.map((lead) => {
    return {
      value: lead.uid,
      label: (
        <LeadSelectOption
          option={{
            uid: lead.uid,
            propertyUid: lead.property?.uid,
            checkInLocalDateTime: lead.checkInLocalDateTime,
            checkOutLocalDateTime: lead.checkOutLocalDateTime,
            propertyPicture: lead.property?.mainPicture.tinyThumbnail,
            guestInformation: {
              firstName: lead.firstName,
              lastName: lead.firstName,
              email: lead.email,
            },
          }}
        />
      ),
    };
  });

  return (
    <FormWithProvider schema={schema()} onSubmit={handleSelect}>
      <ModalHeader>
        <ModalTitle>{t('componentLead.overlap.title')}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <LeadsOverlapModalBody>
          <Col xs={12} sm={4}>
            <p>{t('componentLead.overlap.selectLead')}</p>
          </Col>
          <Col xs={12} sm={8}>
            <CustomSelectField name="leadUid" options={options} />
          </Col>
        </LeadsOverlapModalBody>
      </ModalBody>
      <ModalFooter>
        <Button bsStyle="default" onClick={handleCancel}>
          {t('common.cancel')}
        </Button>
        <Button bsStyle="primary" type="submit">
          {t('common.select')}
        </Button>
      </ModalFooter>
    </FormWithProvider>
  );
};

export default LeadsOverlapModal;
