import { ReactNode } from 'react';
import { PropertyBase } from 'models/Properties';
import { PropertiesDayCellDataMap } from 'pages/stackedCalendar/Calendar.types';
import { UseRestrictionsCheckboxesReturn } from './PropertyPricingModalRestrictions.types';

export enum DailyRateUpdateType {
  FIXED_AMOUNT = 'FIXED_AMOUNT',
  PERCENTAGE_CHANGE = 'PERCENTAGE_CHANGE',
}

export interface PropertyPricingModalContextProps {
  modalId: string;
  params: PropertyPricingModalParams;
  checkInCheckboxes: UseRestrictionsCheckboxesReturn['checkIn'];
  checkoutCheckboxes: UseRestrictionsCheckboxesReturn['checkout'];
  getChangedCheckboxes: UseRestrictionsCheckboxesReturn['getChangedCheckboxes'];
  setSelectionDates: React.Dispatch<
    React.SetStateAction<{ start: Date; end: Date }>
  >;
}

export interface PropertyPricingModalParams {
  min: Date;
  max: Date;
  propertyUids: string[];
  propertiesPricingData: PropertiesPricingData;
  propertiesDayCellDataMap: PropertiesDayCellDataMap;
}

export interface PropertyPricingProps {
  modalId: string;
  params: PropertyPricingModalParams;
}

export interface PropertyPricingData {
  minDailyRate: number;
  maxDailyRate: number;
}

export interface PropertiesPricingData {
  pricing: {
    [currency: string]: {
      maxDailyRate?: number;
      minDailyRate?: number;
      propertyUids: Set<string>;
    };
  };
  minMinimumStay: number;
  maxMinimumStay: number;
}

export interface PropertiesPricingItem {
  currency: string;
  dailyRateUpdateType: DailyRateUpdateType;
  dailyRateValue: number;
  propertyUids: Set<string>;
}

export interface PropertiesPricingForm {
  startDate: Date;
  endDate: Date;
  pricing: PropertiesPricingItem[];
  minimumStay: number;
}

interface CheckInCheckOutRestrictionUpdates {
  allowedOn: {
    SUNDAY?: boolean;
    MONDAY?: boolean;
    TUESDAY?: boolean;
    WEDNESDAY?: boolean;
    THURSDAY?: boolean;
    FRIDAY?: boolean;
    SATURDAY?: boolean;
  };
  propertyUids: string[];
}

export interface PricingPeriodsUpdatePayload {
  startDate: string;
  endDate: string;
  dailyRateUpdates?: (Omit<PropertiesPricingItem, 'propertyUids'> & {
    propertyUids: string[];
  })[];
  minimumStayUpdates?: {
    minimumStay: number;
    propertyUids: string[];
  };
  checkInRestrictionUpdates?: CheckInCheckOutRestrictionUpdates;
  checkOutRestrictionUpdates?: CheckInCheckOutRestrictionUpdates;
}

export type PricingProperty = Pick<PropertyBase, 'uid' | 'name'> & {
  pricing: Pick<PropertyBase['pricing'], 'automatedPricingEnabled'>;
};

export interface PricingPropertyResponse {
  data: {
    properties: PricingProperty[];
  };
}
