import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import LeadSearchField from 'components/forms/domain/lead/LeadSearchField';
import InboxContext from '../InboxContext';

const InboxLeadFilter = () => {
  const { t } = useTranslation();
  const { leadSearchFieldRef, properties } = useContext(InboxContext);

  return (
    <LeadSearchField
      align="right"
      containerClassName="inbox-lead-search"
      colSmInput={12}
      name="lead"
      placeholder={t('form.guestNameEmail')}
      instanceRef={leadSearchFieldRef}
      preFetchedProperties={properties}
    />
  );
};

export default InboxLeadFilter;
