import { Button, Col, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import useAppModal from '../../../../../../../hooks/useAppModal';
import {
  GuestMisconductMutationArgs,
  useReportGuestMisConduct,
} from '../bookingDotComActionHooks';
import FormWithProvider from '../../../../../../forms/form/Form';
import { ModalRow } from '../LeadModalActionsTab.styles';
import { DamageType } from '../../../../../../../models/channelactions/GuestMisconductBody';
import { GuestMisconductFormBody } from './GuestMisconductFormBody';
import { MisconductFormInputs } from './MisconductFormInputs';
import { useMisconductCategories } from './useMisconductCategories';

export const GUEST_MISCONDUCT_MODAL_ID =
  'bookingdotcom-channel-actions-guest-misconduct';

export const GuestMisconductModal = ({ leadUid, currency }) => {
  const { t } = useTranslation();
  const { closeModal } = useAppModal();
  const { isLoading, guestMisconductMutation } = useReportGuestMisConduct();
  const { isCategoryHasSupCategory, isPropertyDamage } =
    useMisconductCategories();
  const handleHide = () => {
    closeModal(GUEST_MISCONDUCT_MODAL_ID);
  };

  function mapFormData(
    formData: MisconductFormInputs,
  ): GuestMisconductMutationArgs {
    return {
      leadUid,
      data: {
        categoryId: formData.category,
        subcategoryId: isCategoryHasSupCategory(formData.category)
          ? formData.subCategory
          : undefined,
        damageType: isPropertyDamage(formData.category)
          ? DamageType[formData.damageType]
          : undefined,
        damageCost: isPropertyDamage(formData.category)
          ? formData.damageCost
          : undefined,
        detailsText: formData.detailsText,
        escalateReport: false,
        rebookingAllowed: false,
      },
    };
  }

  const handleSubmit = (data: MisconductFormInputs) => {
    guestMisconductMutation(mapFormData(data), {
      onSettled: handleHide,
    });
  };

  function guestMisconductSchema() {
    return {
      damageCost: Yup.number().optional().positive(),
    };
  }

  return (
    <FormWithProvider
      defaultValues={{
        category: undefined,
        subCategory: undefined,
        category2: undefined,
      }}
      schema={guestMisconductSchema()}
      onSubmit={handleSubmit}
    >
      <Modal.Header closeButton>
        <Modal.Title id="modal-title">
          {t('componentLead.modal.actionsTab.guestMisconduct.modalTitle')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ModalRow>
          <Col sm={12}>
            {t(
              'componentLead.modal.actionsTab.guestMisconduct.modalDescription',
            )}
          </Col>
        </ModalRow>
        <GuestMisconductFormBody currency={currency} />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleHide} disabled={isLoading}>
          {t('componentLead.modal.actionsTab.buttonCancel')}
        </Button>
        <Button
          bsStyle="primary"
          type="submit"
          disabled={isLoading}
          data-testid="confirm-button"
        >
          {t('componentLead.modal.actionsTab.buttonReport')}
        </Button>
      </Modal.Footer>
    </FormWithProvider>
  );
};
