import { Button, Col, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import useAppModal from '../../../../../../../hooks/useAppModal';
import { useReportChangeStayDates } from '../bookingDotComActionHooks';
import FormWithProvider from '../../../../../../forms/form/Form';
import DateTimePickerField from '../../../../../../forms/dateTimePickerField/DateTimePickerField';
import TextField from '../../../../../../forms/textField/TextField';
import { ModalRow } from '../LeadModalActionsTab.styles';
import useGetCurrencySymbol from '../../../../../../../hooks/useGetCurrencySymbol';

export interface ChangeStayDatesModalProps {
  leadUid: string;
  price: number;
  checkIn: Date;
  checkOut: Date;
  currency: string;
}

export const CHANGE_STAY_DATES_MODAL_ID =
  'bookingdotcom-channel-actions-change-stay-dates';

function changeStayDatesSchema(checkIn: Date) {
  return {
    newCheckOutDate: Yup.date().required().min(checkIn),
    newPrice: Yup.number().required().positive(),
  };
}

export const ChangeStayDatesModal = ({
  checkIn,
  checkOut,
  price,
  leadUid,
  currency,
}: ChangeStayDatesModalProps) => {
  const { t } = useTranslation();
  const { closeModal } = useAppModal();
  const { isLoading, changeStayDatesMutation } = useReportChangeStayDates();
  const getCurrencySymbol = useGetCurrencySymbol();
  const handleHide = () => {
    closeModal(CHANGE_STAY_DATES_MODAL_ID);
  };
  const handleSubmit = (data) => {
    const args = {
      leadUid,
      checkIn: data.checkInDate,
      checkOut: data.newCheckOutDate,
      price: data.newPrice,
    };
    changeStayDatesMutation(args, { onSettled: handleHide });
  };
  return (
    <FormWithProvider
      defaultValues={{
        newCheckOutDate: checkOut,
        checkInDate: checkIn,
        checkOutDate: checkOut,
        price,
        newPrice: price,
      }}
      schema={changeStayDatesSchema(checkIn)}
      onSubmit={handleSubmit}
    >
      <Modal.Header closeButton>
        <Modal.Title id="modal-title">
          {t('componentLead.modal.actionsTab.changeStayDates.modalTitle')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ModalRow>
          <Col sm={12}>
            {t(
              'componentLead.modal.actionsTab.changeStayDates.modalDescription',
            )}
          </Col>
        </ModalRow>
        <ModalRow>
          <DateTimePickerField
            name="checkInDate"
            label={t(
              'componentLead.modal.actionsTab.changeStayDates.fieldCheckInDate',
            )}
            colSmInput={7}
            colSmLabel={4}
            disabled
          />
        </ModalRow>
        <ModalRow>
          <DateTimePickerField
            name="checkOutDate"
            label={t(
              'componentLead.modal.actionsTab.changeStayDates.fieldCheckOutDate',
            )}
            colSmInput={7}
            colSmLabel={4}
            disabled
          />
        </ModalRow>
        <ModalRow>
          <TextField
            colSmInput={7}
            colSmLabel={4}
            label={t(
              'componentLead.modal.actionsTab.changeStayDates.fieldPrice',
            )}
            name="price"
            leftAddon={currency}
            step="any"
            disabled
          />
        </ModalRow>

        <ModalRow>
          <Col sm={12}>
            {t(
              'componentLead.modal.actionsTab.changeStayDates.separatorNewValues',
            )}
          </Col>
        </ModalRow>
        <ModalRow>
          <DateTimePickerField
            name="newCheckOutDate"
            label={t(
              'componentLead.modal.actionsTab.changeStayDates.fieldNewCheckOutDate',
            )}
            colSmInput={7}
            colSmLabel={4}
          />
        </ModalRow>
        <ModalRow>
          <TextField
            colSmInput={7}
            colSmLabel={4}
            label={t(
              'componentLead.modal.actionsTab.changeStayDates.fieldNewPrice',
            )}
            name="newPrice"
            leftAddon={getCurrencySymbol(currency)}
            step="any"
            type="number"
          />
        </ModalRow>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleHide} disabled={isLoading}>
          Cancel
        </Button>
        <Button
          bsStyle="primary"
          type="submit"
          disabled={isLoading}
          data-testid="confirm-button"
        >
          Report
        </Button>
      </Modal.Footer>
    </FormWithProvider>
  );
};
