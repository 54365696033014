import { useState } from 'react';
import { Collapse as BSCollapse } from 'react-bootstrap';
import ToggleButton from 'components/button/ToggleButton';
import { CollapseStyled } from './Collapse.styles';
import { CollapseProps } from './Collapse.types';

const Collapse = ({
  children,
  defaultExpanded,
  label,
  variant,
  ...props
}: CollapseProps) => {
  const [isExpanded, setExpanded] = useState(
    defaultExpanded !== undefined ? defaultExpanded : false,
  );

  const handleOnClick = () => {
    setExpanded((currentIsExpanded) => !currentIsExpanded);
  };

  return (
    <CollapseStyled
      $isExpanded={isExpanded}
      $variant={variant ?? 'unstyled'}
      {...props}
    >
      <div className="collapsible-header" data-testid="collapse-header">
        <div className="collapsible-header-label">{label}</div>
        <ToggleButton
          isExpanded={isExpanded}
          onClick={handleOnClick}
          toggleVertically
        />
      </div>
      <BSCollapse in={isExpanded}>
        <div className="collapsible-body" data-testid="collapse-body">
          {children}
        </div>
      </BSCollapse>
    </CollapseStyled>
  );
};

Collapse.defaultProps = {
  defaultExpanded: undefined,
  label: undefined,
  variant: undefined,
};

export default Collapse;
