import { useContext, useEffect } from 'react';
import { Col, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useAppModal from 'hooks/useAppModal';
import DateTimePickerField from 'components/forms/dateTimePickerField/DateTimePickerField';
import TextField from 'components/forms/textField/TextField';
import Button from 'components/button/Button';
import Collapse from 'components/collapse/Collapse';
import DailyRateField from 'components/forms/dailyRateField/DailyRateField';
import useCalendarBodySelection from 'pages/calendar/common/body/selection/useCalendarBodySelection';
import { useFormState, useWatch } from 'react-hook-form';
import { PropertyPricingWrapper } from './PropertyPricingModal.styles';
import PropertyPricingModalContext from './PropertyPricingModalContext';
import usePropertyPricingForm from './usePropertyPricingForm';
import { PropertiesPricingData } from './PropertyPricingModal.types';
import PropertyPricingModalRestrictions from './PropertyPricingModalRestrictions';

const PropertyPricingModalBodyInner = ({
  propertiesPricingData,
}: {
  propertiesPricingData: PropertiesPricingData;
}) => {
  const { t } = useTranslation();

  const { updateSelectionDates, resetSelection } = useCalendarBodySelection();
  const { modalId, setSelectionDates } = useContext(
    PropertyPricingModalContext,
  );

  const { closeModal } = useAppModal();
  const cancelHandler = () => {
    resetSelection();
    closeModal(modalId);
  };
  const { isUpdating } = usePropertyPricingForm();
  const { isSubmitting, errors } = useFormState();
  const { startDate, endDate } = useWatch();

  useEffect(() => {
    updateSelectionDates(startDate, endDate);
    setSelectionDates({ start: startDate, end: endDate });
  }, [startDate, endDate, setSelectionDates]);

  return (
    <>
      <Modal.Body>
        <PropertyPricingWrapper>
          <DateTimePickerField
            name="startDate"
            label={t('componentProperty.modal.form.startDate')}
            colSmInput={5}
            colSmOffsetInput={3}
            colSmLabel={4}
          />

          <DateTimePickerField
            name="endDate"
            label={t('componentProperty.modal.form.endDate')}
            colSmInput={5}
            colSmOffsetInput={3}
            colSmLabel={4}
          />

          <Collapse
            defaultExpanded
            label={t('componentProperty.modal.pricing')}
          >
            {Object.keys(propertiesPricingData.pricing).map((key, index) => {
              return (
                <DailyRateField
                  key={key}
                  id={`${index}`}
                  currency={key}
                  name={`pricing.${index}`}
                  placeholder={
                    propertiesPricingData.pricing[key].minDailyRate ===
                    propertiesPricingData.pricing[key].maxDailyRate
                      ? `${propertiesPricingData.pricing[key].minDailyRate}`
                      : `${propertiesPricingData.pricing[key].minDailyRate} - ${propertiesPricingData.pricing[key].maxDailyRate}`
                  }
                />
              );
            })}

            <TextField
              label={t('componentProperty.modal.form.minimumStay')}
              name="minimumStay"
              type="number"
              min={0}
              colSmInput={5}
              colSmOffsetInput={3}
              colSmLabel={4}
              placeholder={
                propertiesPricingData.minMinimumStay ===
                propertiesPricingData.maxMinimumStay
                  ? `${propertiesPricingData.minMinimumStay}`
                  : `${propertiesPricingData.minMinimumStay} - ${propertiesPricingData.maxMinimumStay}`
              }
              rightAddons={[t('componentProperty.modal.form.nights')]}
            />
          </Collapse>

          <Collapse
            data-testid="check-in-checkout-restrictions"
            defaultExpanded={false}
            label={t('componentProperty.modal.checkInCheckoutRestrictions')}
          >
            <PropertyPricingModalRestrictions />
          </Collapse>
        </PropertyPricingWrapper>
      </Modal.Body>

      <Modal.Footer data-testid="pricing-modal-footer">
        <Col sm={12}>
          {errors.invalid && (
            <p className="text-danger text-center">
              {errors.invalid.message?.toString()}
            </p>
          )}
        </Col>
        <Col sm={12}>
          <Button
            data-testid="pricing-modal-save-button"
            bsStyle="primary"
            disabled={isSubmitting || isUpdating}
            type="submit"
          >
            {isSubmitting || isUpdating ? t('common.saving') : t(`common.save`)}
          </Button>
          <Button
            data-testid="pricing-modal-cancel-button"
            disabled={isSubmitting || isUpdating}
            onClick={cancelHandler}
          >
            {t('common.cancel')}
          </Button>
        </Col>
      </Modal.Footer>
    </>
  );
};

export default PropertyPricingModalBodyInner;
