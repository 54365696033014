import React from 'react';
import { Property, PropertyUnit } from 'models/Properties';
import { PropertiesEntry, PropertyEntry } from 'models/PropertiesEntries';
import { Lead, LeadSource, LeadStatus } from 'models/Leads';
import { Job } from 'models/Jobs';

export type GetCalendarPropertiesResponse = {
  data: {
    properties: Property[];
  };
};

export type PropertiesMap = {
  [uid: string]: Property & {
    unitTypeUid?: string;
    units: PropertyUnit[] | null;
  };
};

export enum StackedCalendarMode {
  BOOKING = 'BOOKING',
  PRICING = 'PRICING',
  COMBINED = 'COMBINED',
}

export interface CalendarLead {
  adultCount: number;
  checkInLocalDateTime: string;
  checkInDate?: Date;
  checkOutLocalDateTime: string;
  checkOutDate?: Date;
  creator: any;
  email: string;
  firstName: string;
  lastName: string;
  order: {
    currency: string;
    totalAmount: number;
  };
  property: {
    uid: string;
    mainPicture?: {
      tinyThumbnail?: string;
    };
  };
  source: LeadSource;
  status: LeadStatus;
  uid: string;
  unitUid?: string;
  checkInUtcDateTime?: string;
  checkOutUtcDateTime?: string;
  checkInUtcDate?: Date;
  checkOutUtcDate?: Date;
}

export interface DayCellData {
  dayDate: Date;
  entry?: PropertyEntry;
  isEmpty?: boolean;
  isGrayedOut?: boolean;
  isLoading?: boolean;
  isWeekend?: boolean;
  leads?: string[];
  jobs?: string[];
}

export interface LeadsMap {
  [leadUid: string]: CalendarLead;
}

export interface JobsMap {
  [jobUid: string]: Job;
}

export interface PropertiesDayCellDataMap {
  [uid: string]: {
    [date: string]: DayCellData;
  };
}

export interface LoadedDateRange {
  from: Date;
  to: Date;
}

export interface CalendarBodyRefreshRequest {
  properties: string[];
  fromDate: Date;
  toDate: Date;
  shouldHighlightUpdatedCells?: boolean;
}

export type CalendarContextProps = {
  isFilterOpen: boolean;
  setIsFilterOpen: React.Dispatch<React.SetStateAction<boolean>>;
  filter: CalendarFilter;
  setFilter: React.Dispatch<React.SetStateAction<CalendarFilter>>;
  allProperties: Property[];
  setAllProperties: React.Dispatch<React.SetStateAction<Property[]>>;
  mode: StackedCalendarMode;
  setMode: React.Dispatch<React.SetStateAction<StackedCalendarMode>>;
  propertiesMap: PropertiesMap;
  updatePropertiesMap: React.Dispatch<
    React.SetStateAction<{ properties: Property[] }>
  >;
  selectedProperty?: string;
  setSelectedProperty: React.Dispatch<React.SetStateAction<string>>;
  totalPropertiesNumber: number;
  setTotalPropertiesNumber: React.Dispatch<React.SetStateAction<number>>;
  bodyWidth: number;
  setBodyWidth: React.Dispatch<React.SetStateAction<number>>;
  leadsMap: LeadsMap;
  jobsMap: JobsMap;
  daysLeadsMap: React.MutableRefObject<
    Record<
      string,
      {
        uid: string;
        checkIn: Date;
        checkOut: Date;
      }[]
    >
  >;
};

interface MultiSelectOption {
  label: string;
  value: string;
}

interface MultiSelectAmenity extends MultiSelectOption {
  isCustom: boolean;
}

export interface GetCalendarPropertiesResponseTO {
  data: {
    properties: Property[];
  };
}

export interface CalendarFilter {
  agentUid: string;
  amenities: MultiSelectAmenity[];
  availableFrom: Date;
  availableTo: Date;
  bedrooms: [number, number];
  dailyRate: [number, number];
  guests: [number, number];
  location: string;
  status: string;
  tags: MultiSelectOption[];
  type: string;
  offset?: number;
  limit?: number;
}

export type CalendarDayCellsData = [
  properties: PropertiesEntry[],
  leads: Lead[],
  jobs: Job[],
];
