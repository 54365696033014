export enum DamageType {
  SMALL_FURNITURE = 'SMALL_FURNITURE',
  LARGE_FURNITURE = 'LARGE_FURNITURE',
  DOOR_WINDOW_WALL = 'DOOR_WINDOW_WALL',
  PROPERTY_DAMAGE_OTHER = 'PROPERTY_DAMAGE_OTHER',
}

export interface GuestMisconductBody {
  damageType?: DamageType;
  damagePreventingNextReservation?: boolean;
  categoryId: number;
  subcategoryId?: number;
  detailsText?: string;
  escalateReport: boolean;
  rebookingAllowed: boolean;
  damageCost?: number;
}

export interface MisconductSubCategory {
  id: number;
  title: string;
}

export interface MisconductCategory {
  id: number;
  title: string;
  subcategories?: MisconductSubCategory[];
}
