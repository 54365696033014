import { useContext } from 'react';
import { format } from 'date-fns';
import {
  DailyRateUpdateType,
  PricingPeriodsUpdatePayload,
  PropertiesPricingData,
  PropertiesPricingForm,
} from './PropertyPricingModal.types';
import useUpdatePropertiesPricing from './useUpdatePropertiesPricing';
import PropertyPricingModalContext from './PropertyPricingModalContext';

const usePropertyPricingForm = () => {
  const { isUpdating, updatePropertiesPricing } = useUpdatePropertiesPricing();
  const { params, getChangedCheckboxes } = useContext(
    PropertyPricingModalContext,
  );

  const handleSubmit = (formValues: PropertiesPricingForm) => {
    const { startDate, endDate, pricing, minimumStay } = formValues;

    const dailyRateUpdates = pricing.filter(
      ({ dailyRateValue }) => !!dailyRateValue,
    );

    const payload: PricingPeriodsUpdatePayload = {
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
    };

    if (dailyRateUpdates.length) {
      payload.dailyRateUpdates = dailyRateUpdates.map(
        ({ propertyUids, ...rest }) => ({
          propertyUids: Array.from(propertyUids),
          ...rest,
        }),
      );
    }
    if (minimumStay) {
      payload.minimumStayUpdates = {
        minimumStay,
        propertyUids: pricing
          .map(({ propertyUids }) => propertyUids)
          .reduce((acc, set) => [...acc, ...Array.from(set)], []),
      };
    }

    const { changedCheckIn, changedCheckout } = getChangedCheckboxes(
      formValues as unknown as Record<string, unknown>,
    );

    if (changedCheckIn.length) {
      const allowedOn = changedCheckIn.reduce((res, o) => {
        return {
          ...res,
          [o.name.toUpperCase()]: o.value,
        };
      }, {});

      payload.checkInRestrictionUpdates = {
        allowedOn,
        propertyUids: params.propertyUids,
      };
    }

    if (changedCheckout.length) {
      const allowedOn = changedCheckout.reduce((res, o) => {
        return {
          ...res,
          [o.name.toUpperCase()]: o.value,
        };
      }, {});

      payload.checkOutRestrictionUpdates = {
        allowedOn,
        propertyUids: params.propertyUids,
      };
    }

    updatePropertiesPricing(payload);
  };

  const getDefaultValues = (
    min: Date,
    max: Date,
    propertiesPricingData: PropertiesPricingData,
  ) => {
    const defaultValues = {
      startDate: min,
      endDate: max,
      pricing: [],
      minimumStay: null,
    };

    Object.entries(propertiesPricingData.pricing).forEach(
      ([currency, { propertyUids }]) => {
        defaultValues.pricing.push({
          dailyRateUpdateType: DailyRateUpdateType.FIXED_AMOUNT,
          dailyRateValue: null,
          currency,
          propertyUids,
        });
      },
    );

    return defaultValues;
  };

  return {
    getDefaultValues,
    handleSubmit,
    isUpdating,
  };
};

export default usePropertyPricingForm;
