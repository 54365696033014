import usePropertyPricingModal from 'components/domain/propertyPricing/modal/usePropertyPricingModal';
import { preparePropertiesPricingDataForPropertyPricingModal } from 'utils/propertyPricing/propertyPricingUtils';
import useStackedCalendarPropertiesEntries from '../../../stackedCalendar/useStackedCalendarPropertiesEntries';

const useCalendarOpenPricingModal = () => {
  const propertiesEntries = useStackedCalendarPropertiesEntries();
  const { createPropertyPricingModal } = usePropertyPricingModal();

  const openPropertyPricingModal = ({
    dateFrom,
    dateTo,
    propertyUids,
  }: {
    dateFrom: Date;
    dateTo: Date;
    propertyUids: string[];
  }) => {
    createPropertyPricingModal({
      min: dateFrom,
      max: dateTo,
      propertyUids,
      propertiesPricingData:
        preparePropertiesPricingDataForPropertyPricingModal(
          propertyUids,
          propertiesEntries,
          dateFrom,
          dateTo,
        ),
      propertiesDayCellDataMap: propertiesEntries,
    });
  };

  return {
    openPropertyPricingModal,
  };
};

export default useCalendarOpenPricingModal;
