import { PropertyPricingProps } from './PropertyPricingModal.types';
import PropertyPricingModalBody from './PropertyPricingModalBody';
import PropertyPricingModalContextProvider from './PropertyPricingModalContextProvider';

const PropertyPricingModal = ({ modalId, params }: PropertyPricingProps) => {
  return (
    <PropertyPricingModalContextProvider modalId={modalId} params={params}>
      <PropertyPricingModalBody params={params} />
    </PropertyPricingModalContextProvider>
  );
};

export default PropertyPricingModal;
