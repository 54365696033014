import useAppEvent from 'hooks/useAppEvent';
import { AppEventType } from 'models/Events';
import { Lead, LeadStatus } from 'models/Leads';
import useAppMutation from 'hooks/useAppMutation';
import useAppUser from 'hooks/useAppUser';
import API from 'services/API';
import { LEAD_GQL_QUERY_BASE } from '../useFetchLead';

const fields = `
  uid,
  status
`;

/**
 * This hook is intent to use when we don't know if the entity is a lead or a block.
 *
 * We then need to fetch the entity, and then decide if it's a lead or a block.
 */
const useUpdateLeadOrBlockModal = () => {
  const { agencyUid } = useAppUser();
  const { publish } = useAppEvent();

  const { mutateAsync } = useAppMutation({
    mutationKey: [LEAD_GQL_QUERY_BASE, fields],
    mutationFn: async (entityUid: string): Promise<Lead> => {
      const leadsResponse = await API.post('/v3/graphql', {
        // for all the available fields check com.orbirental.api.v3.TOs.graphql.LeadGraphQLApiTO
        query: `{leads (
          agencyUid:"${agencyUid}", 
          uids:["${entityUid}"]
        ){
          ${fields}
        }}`,
        variables: {},
      });
      return leadsResponse?.data?.data?.leads?.length
        ? leadsResponse.data.data.leads[0]
        : null;
    },
  });

  const openModal = (entityUid: string) => {
    mutateAsync(entityUid).then((lead) => {
      if (lead.status === LeadStatus.BLOCKED) {
        publish(AppEventType.OPEN_UPDATE_BLOCK_MODAL, {
          blockUid: entityUid,
        });
      } else {
        publish(AppEventType.OPEN_UPDATE_LEAD_MODAL, {
          params: {
            leadUid: entityUid,
          },
        });
      }
    });
  };

  return { openModal };
};

export default useUpdateLeadOrBlockModal;
