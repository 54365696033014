import styled from 'styled-components';
import theme from 'styles/theme';

export const LeadPopoverActionsList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;

  > li {
    padding: 8px 14px;
  }

  > :not(:first-child) {
    border-top: 1px solid ${theme.colors.gray300};
  }

  button {
    color: inherit;
    padding: 0;
    text-wrap: wrap;
    text-align: left;
    word-break: break-word;

    :focus,
    :hover {
      color: inherit;
    }

    :active:focus,
    :focus {
      outline: none;
    }
  }
`;
