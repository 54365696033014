import {
  LeadSearchFieldProps,
  LeadsSearchResponseItem,
} from './LeadSearchField.types';
import { LeadSearchFieldAsyncTypeahead } from './LeadSearchField.styles';
import useLeadSearchField from './useLeadSearchField';
import LeadSelectOption from './LeadSelectOption';

const LeadSearchField: React.FC<LeadSearchFieldProps> = ({
  align,
  name,
  preFetchedProperties,
  ...props
}) => {
  const { searchHandler } = useLeadSearchField(preFetchedProperties);

  return (
    <LeadSearchFieldAsyncTypeahead
      align={align}
      name={name}
      labelKey={(option) => `${option.bestProfile}`}
      searchHandler={searchHandler}
      renderMenuItemChildren={(option: LeadsSearchResponseItem) => (
        <LeadSelectOption option={option} />
      )}
      {...props}
    />
  );
};

export default LeadSearchField;
