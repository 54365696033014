import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const Container = styled.section`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f3f3f7;
  margin-top: 51px;
  padding: 25px 0;

  h3 {
    margin-bottom: 50px;
    font-weight: bold;
  }

  ${mediaQuery.sm} {
    h3 {
      text-align: center;
    }
  }
`;

export const StepWrapper = styled.div`
  width: 100%;
  max-width: 1180px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  ${mediaQuery.sm} {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;

  p {
    font-weight: bold;
    font-size: 24px;
  }

  span {
    font-weight: 400;
    font-size: 24px;
  }
`;

export const TurnoConnectButton = styled.a`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 100px;
  border-radius: 28px;
  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0px;
  min-width: 180px;
  height: 56px;
  background: #33cc99;
  border: 1px solid #1d9970;
  color: #fff;

  &:hover,
  &:active,
  &:focus,
  &:active:focus,
  &:active:hover {
    background: #2bbd8c;
    border: 1px solid #1d9970;
    color: #fff;
  }
`;

export const Divider = styled.div`
  width: 1px;
  background: rgba(0, 0, 0, 0.1);
  height: 200px;

  ${mediaQuery.sm} {
    height: 1px;
    width: 80%;
  }
`;
