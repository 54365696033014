import { BaseResponse } from './_base';

export enum FeeAmountType {
  // eslint-disable-next-line @typescript-eslint/prefer-enum-initializers
  AMOUNT,
  // eslint-disable-next-line @typescript-eslint/prefer-enum-initializers
  TAX,
}

export enum FeeScope {
  // eslint-disable-next-line @typescript-eslint/prefer-enum-initializers
  PER_NIGHT,
  // eslint-disable-next-line @typescript-eslint/prefer-enum-initializers
  PER_STAY,
  // eslint-disable-next-line @typescript-eslint/prefer-enum-initializers
  PER_GUEST_PER_NIGHT,
  // eslint-disable-next-line @typescript-eslint/prefer-enum-initializers
  PER_GUEST,
}

export enum FeeType {
  // eslint-disable-next-line @typescript-eslint/prefer-enum-initializers
  CLEANING,
  // eslint-disable-next-line @typescript-eslint/prefer-enum-initializers
  EARLY_ARRIVAL,
  // eslint-disable-next-line @typescript-eslint/prefer-enum-initializers
  LATE_ARRIVAL,
  // eslint-disable-next-line @typescript-eslint/prefer-enum-initializers
  PET,
  // eslint-disable-next-line @typescript-eslint/prefer-enum-initializers
  TAX,
  // eslint-disable-next-line @typescript-eslint/prefer-enum-initializers
  CUSTOM,
}

export interface PropertyFee {
  name: string;
  uid: string;
  propertyUid: string;
  type: FeeType;
  optional: boolean;
  amount: number;
  amountType: FeeAmountType;
  taxationRate: number;
  scope: FeeScope;
  longTermStayExemption: number;
  attestation: boolean;
}

export interface GetPropertyFeesResponseTO extends BaseResponse {
  fees: PropertyFee[];
}
