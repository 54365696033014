import { getDay, isWithinInterval } from 'date-fns';
import { PropertiesDayCellDataMap } from 'pages/stackedCalendar/Calendar.types';
import { CheckboxOption } from 'components/forms/checkboxGroupField/CheckboxGroupField';
import { extractCheckboxesValue } from 'components/forms/checkboxGroupField/CheckboxGroupField.utils';
import { differenceWith } from 'utils/arrayUtils';
import { isDeepEqual } from 'utils/objectUtils';
import {
  RestrictionsCheckboxes,
  WeekDaysAvailability,
} from './PropertyPricingModalRestrictions.types';

function dayToWeekDayKey(
  day: 0 | 1 | 2 | 3 | 4 | 5 | 6,
): keyof WeekDaysAvailability {
  switch (day) {
    case 1:
      return 'monday';
    case 2:
      return 'tuesday';
    case 3:
      return 'wednesday';
    case 4:
      return 'thursday';
    case 5:
      return 'friday';
    case 6:
      return 'saturday';
    case 0:
      return 'sunday';
    default:
      return 'sunday';
  }
}

export function dayCellToWeekdaysAvailability(
  dayCellsData: PropertiesDayCellDataMap[string],
  start: Date,
  end: Date,
): WeekDaysAvailability {
  const dayCells = Object.values(dayCellsData);
  const initialValue = {
    monday: { checkIn: [], checkout: [] },
    tuesday: { checkIn: [], checkout: [] },
    wednesday: { checkIn: [], checkout: [] },
    thursday: { checkIn: [], checkout: [] },
    friday: { checkIn: [], checkout: [] },
    saturday: { checkIn: [], checkout: [] },
    sunday: { checkIn: [], checkout: [] },
  };

  return dayCells.reduce((weekDays, dayCell) => {
    if (
      // It seems after saving the pricing modal, an `isLoading: true` is added to the object
      typeof dayCell === 'boolean' ||
      // Skip day if its outside selected range
      !isWithinInterval(dayCell.dayDate, { start, end })
    ) {
      return weekDays;
    }

    const weekDay = getDay(dayCell.dayDate);
    const weekDayKey = dayToWeekDayKey(weekDay);

    return {
      ...weekDays,
      [weekDayKey]: {
        checkIn: [
          ...weekDays[weekDayKey].checkIn,
          dayCell.entry.availability.availableForCheckIn,
        ],
        checkout: [
          ...weekDays[weekDayKey].checkout,
          dayCell.entry.availability.availableForCheckOut,
        ],
      },
    };
  }, initialValue);
}

// Merge the checkboxes for different properties
export function mergeCheckboxes(currentCheckboxes: CheckboxOption[]) {
  return (checkbox: CheckboxOption, index: number): CheckboxOption => {
    const currentCheckbox = currentCheckboxes[index];

    // If there's no checkbox yet for the specific week day
    if (!currentCheckbox) {
      return checkbox;
    }

    // If the checkbox is already indeterminate for the previous property, it'll
    // be indeterminate for the next property as well
    if ('indeterminate' in currentCheckbox && currentCheckbox.indeterminate) {
      return currentCheckbox;
    }

    // If the both checkboxes are either both checked or both unchecked
    if (
      (currentCheckbox.value && checkbox.value) ||
      (!currentCheckbox.value && !checkbox.value)
    ) {
      return checkbox;
    }

    // If checkbox is different between properties, it should be indeterminate
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { value, ...rest } = checkbox;
    return { ...rest, indeterminate: true };
  };
}

export function getChangedCheckboxes(
  initialRestrictionCheckboxesState: RestrictionsCheckboxes,
) {
  return (formValues: Record<string, unknown>) => {
    const {
      checkIn: initialCheckInCheckboxes,
      checkout: initialCheckoutCheckboxes,
    } = initialRestrictionCheckboxesState;

    const currentCheckInCheckboxes = extractCheckboxesValue(
      formValues,
      'checkIn',
      initialCheckInCheckboxes,
    );

    const currentCheckoutCheckboxes = extractCheckboxesValue(
      formValues,
      'checkout',
      initialCheckoutCheckboxes,
    );

    const changedCheckIn = differenceWith(
      currentCheckInCheckboxes,
      initialCheckInCheckboxes,
      isDeepEqual,
    );

    const changedCheckout = differenceWith(
      currentCheckoutCheckboxes,
      initialCheckoutCheckboxes,
      isDeepEqual,
    );

    return { changedCheckIn, changedCheckout };
  };
}
