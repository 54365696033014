import useAppQuery from '../../../hooks/useAppQuery';
import { Lead } from '../../../models/Leads';
import API from '../../../services/API';
import useAppUser from '../../../hooks/useAppUser';

export const LEAD_GQL_QUERY_BASE = 'lead-gql';

const useFetchLead = ({
  leadUid,
  fields,
  enabled = true,
}: {
  leadUid: string | undefined;
  fields: string;
  enabled?: boolean;
}) => {
  const { agencyUid } = useAppUser(); // TODO userUid for owner

  const {
    data: lead,
    error,
    isInitialLoading: isFetching,
    refetch,
  } = useAppQuery(
    [LEAD_GQL_QUERY_BASE, leadUid, fields],
    async (): Promise<Lead> => {
      const leadsResponse = await API.post('/v3/graphql', {
        // for all the available fields check com.orbirental.api.v3.TOs.graphql.LeadGraphQLApiTO
        query: `{leads (
            agencyUid:"${agencyUid}", 
            uids:["${leadUid}"]
          ){
            ${fields}
          }}`,
        variables: {},
      });
      return leadsResponse?.data?.data?.leads?.length
        ? leadsResponse.data.data.leads[0]
        : null;
    },
    {
      enabled: enabled && !!leadUid,
    },
  );

  return {
    error,
    isFetching,
    refetch,
    lead,
  };
};

export default useFetchLead;
