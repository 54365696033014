import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import PageHeader from 'components/pageHeader/PageHeader';

export const LeadDataFormColumn = styled(Col)`
  display: flex;
  flex-direction: column;
  padding-top: 25px;

  hr {
    width: 100%;
    float: left;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    border-top: 1px solid #eee;
  }
`;

export const CustomDataWrapper = styled.div`
  padding-top: 20px;
  display: block;
  float: left;
  width: 100%;
`;

export const CustomDataColumn = styled(Col)`
  display: flex;
  flex-direction: column;
  padding-top: 0px;
`;

export const CustomDataPageHeader = styled(PageHeader)`
  margin-bottom: 15px;
  margin-top: 0px;

  h1 {
    padding-left: 15px;
    span {
      font-size: 18px;
    }
  }

  h2 {
    padding-left: 15px;
  }
`;

export const LeadDataFormRow = styled.div`
  margin-bottom: 15px;

  .form-field-container {
    margin-bottom: 0;
  }
`;
