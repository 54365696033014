import { useContext } from 'react';
import LeadModalContext from '../../LeadModalContext';

export const useLeadActionInitialValues = () => {
  const { lead, quote } = useContext(LeadModalContext);
  return {
    leadUid: lead?.uid,
    price: quote?.totalPrice,
    currency: quote?.currency,
    checkIn: lead?.checkInDate,
    checkOut: lead?.checkOutDate,
  };
};
