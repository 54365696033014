import { AxiosResponse } from 'axios';
import API from 'services/API';
import useAppMutation from 'hooks/useAppMutation';
import { API_MAX_LIMIT } from 'services/API.constants';
import { LeadsSearchPropertiesGraphQLResponse } from './LeadSearchField.types';

const fetchProperties = async ({
  propertyUids,
}: {
  propertyUids: string[];
}) => {
  const response: AxiosResponse<LeadsSearchPropertiesGraphQLResponse> =
    await API.post<LeadsSearchPropertiesGraphQLResponse>('/v3/graphql', {
      operationName: 'SearchPropertiesForLeadsTypeAhead',
      query: `{properties(
                topLevelOnly:false,
                offset:"0",
                limit:"${API_MAX_LIMIT}",
                propertyUids:"${propertyUids}"
                ){
                    uid,
                    mainPicture{tinyThumbnail}
                }
            }`,
      variables: {},
    });

  return response.data.data?.properties || [];
};

const useFetchPropertiesForLeadsSearchMutation = () => {
  return useAppMutation({
    mutationFn: fetchProperties,
  });
};

export default useFetchPropertiesForLeadsSearchMutation;
