import { ReactNode, useMemo, useState } from 'react';
import useRestrictionsCheckboxes from './useRestrictionsCheckboxes';
import AdjustmentModalContext from './PropertyPricingModalContext';
import { PropertyPricingModalParams } from './PropertyPricingModal.types';

interface PropertyPricingModalContextProviderProps {
  children: ReactNode;
  modalId: string;
  params: PropertyPricingModalParams;
}

const PropertyPricingModalContextProvider = ({
  children,
  modalId,
  params,
}: PropertyPricingModalContextProviderProps) => {
  const { propertiesDayCellDataMap, propertyUids, min, max } = params;
  const [selectionDates, setSelectionDates] = useState({
    start: min,
    end: max,
  });

  const {
    checkIn: checkInCheckboxes,
    checkout: checkoutCheckboxes,
    getChangedCheckboxes,
  } = useRestrictionsCheckboxes(
    propertiesDayCellDataMap,
    propertyUids,
    selectionDates,
  );

  const contextValue = useMemo(
    () => ({
      modalId,
      params,
      checkInCheckboxes,
      checkoutCheckboxes,
      getChangedCheckboxes,
      setSelectionDates,
    }),
    [
      modalId,
      params,
      checkInCheckboxes,
      checkoutCheckboxes,
      getChangedCheckboxes,
      setSelectionDates,
    ],
  );

  return (
    <AdjustmentModalContext.Provider value={contextValue}>
      {children}
    </AdjustmentModalContext.Provider>
  );
};

export default PropertyPricingModalContextProvider;
