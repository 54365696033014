import { MenuItem } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useUpgradePlanModal from 'components/domain/property/modal/upgradePlan/useUpgradePlanModal';
import useFetchHotels from 'components/domain/property/useFetchHotels';
import LoadingPlaceholder from 'components/loader/LoadingPlaceholder';
import useFeatures from 'hooks/useFeatures';
import { PropertyBase, PropertyBusinessType } from 'models/Properties';
import { getPropertyAddUnitTypePath } from 'utils/property/propertyUtils';
import { API_MAX_LIMIT } from 'services/API.constants';
import usePropertiesCreationAllowed from '../header/usePropertiesCreationAllowed';
import useAddSubUnitsModal from '../actions/addSubUnits/useAddSubUnitsModal';
import useConvertToUnitTypeModal from '../actions/convertToUnitType/useConvertToUnitTypeModal';
import useLinkSubUnitsModal from '../actions/linkSubUnits/useLinkSubUnitsModal';
import useDeactivateModal from '../actions/confirmation/useDeactivateModal';
import useActivateModal from '../actions/confirmation/useActivateModal';
import useDeleteModal from '../actions/confirmation/useDeleteModal';
import useDuplicateAction from '../actions/useDuplicateAction';
import GearIcon from './icons/GearIcon.svg?react';
import {
  PropertyActionsDropdownButtonStyled,
  PropertyActionsPlaceholderStyled,
} from './PropertyListItem.styles';

interface PropertyActionsProps {
  property: Pick<PropertyBase, 'businessType' | 'uid' | 'name'> &
    Partial<Pick<PropertyBase, 'isActive' | 'subUnits'>>;
}

const PropertyActions = ({ property }: PropertyActionsProps) => {
  const { t } = useTranslation();
  const { data: hotels, isLoading: isFetchingHotels } = useFetchHotels({
    limit: API_MAX_LIMIT,
  });
  const { canAddProperties, openUpgradePlanModal } = useUpgradePlanModal();
  const { openAddSubUnitsModal } = useAddSubUnitsModal();
  const { openConvertToUnitTypeModal } = useConvertToUnitTypeModal();
  const { openLinkSubUnitsModal } = useLinkSubUnitsModal();
  const { openDeactivateModal } = useDeactivateModal();
  const { openActivateModal } = useActivateModal();
  const { openDeleteModal } = useDeleteModal();
  const { handleDuplicate, isDuplicating } = useDuplicateAction();
  const canCreateProperties = usePropertiesCreationAllowed();
  const allowedFlags = useFeatures();
  const { uid: propertyUid, name, businessType } = property;
  const isHotel = businessType === PropertyBusinessType.HOTEL;
  const isMasterUnit = property.subUnits?.length > 0;
  const hasHotels = !!hotels?.length;

  const handleAddSubUnitsClick = () => openAddSubUnitsModal({ propertyUid });

  const handleConvertToUnitTypeClick = () =>
    openConvertToUnitTypeModal({ propertyUid });

  const handleLinkSubUnitsClick = () => openLinkSubUnitsModal({ propertyUid });

  const handleDeactiveClick = () =>
    openDeactivateModal({ uid: propertyUid, name });

  const handleActivateClick = () =>
    openActivateModal({ uid: propertyUid, name });

  const handleDeleteClick = () =>
    openDeleteModal({ uid: propertyUid, name, isHotel, isMasterUnit });

  const handleDuplicateClick: React.MouseEventHandler = (event) => {
    if (!canAddProperties(1)) {
      event.preventDefault();
      openUpgradePlanModal();
    } else {
      handleDuplicate({ uid: propertyUid, name });
    }
  };

  if (isFetchingHotels) {
    return <LoadingPlaceholder width="26px" linesCount={1.3} />;
  }

  if (
    [
      PropertyBusinessType.SUB_UNIT,
      PropertyBusinessType.UNIT_TYPE,
      PropertyBusinessType.UNIT,
    ].includes(businessType)
  ) {
    return <PropertyActionsPlaceholderStyled />;
  }

  return (
    <div id={`property-actions-container-${property.uid}`}>
      <PropertyActionsDropdownButtonStyled
        aria-label={t('pageProperties.actions.propertyActionsMenu')}
        data-testid="property-actions-menu"
        id="property-actions"
        title={<GearIcon />}
        bsStyle="default"
        bsSize="xsmall"
        noCaret
        pullRight
      >
        {property.isActive && (
          <>
            {canCreateProperties && !isHotel && (
              <MenuItem
                data-testid="property-actions-menu-item"
                onClick={handleDuplicateClick}
                disabled={isDuplicating}
              >
                {t('pageProperties.actions.duplicate')}
              </MenuItem>
            )}
            {canCreateProperties && !isHotel && (
              <MenuItem
                data-testid="property-actions-menu-item"
                onClick={handleAddSubUnitsClick}
              >
                {t('pageProperties.actions.addSubUnits')}
              </MenuItem>
            )}
            {!isHotel && (
              <MenuItem
                data-testid="property-actions-menu-item"
                onClick={handleLinkSubUnitsClick}
              >
                {t('pageProperties.actions.linkSubUnits')}
              </MenuItem>
            )}
            {canCreateProperties &&
              hasHotels &&
              allowedFlags.allowMultiUnit &&
              !isHotel &&
              !isMasterUnit && (
                <MenuItem
                  data-testid="property-actions-menu-item"
                  onClick={handleConvertToUnitTypeClick}
                >
                  {t('pageProperties.actions.convertToUnitType')}
                </MenuItem>
              )}
            {canCreateProperties && isHotel && (
              <MenuItem
                data-testid="property-actions-menu-item"
                href={getPropertyAddUnitTypePath(property)}
              >
                {t('pageProperties.actions.createUnitType')}
              </MenuItem>
            )}

            {canCreateProperties && !isHotel && (
              <MenuItem
                data-testid="property-actions-menu-item"
                onClick={handleDeactiveClick}
              >
                {t('pageProperties.actions.deactivate')}
              </MenuItem>
            )}
          </>
        )}

        {canCreateProperties && !property.isActive && !isHotel && (
          <MenuItem
            data-testid="property-actions-menu-item"
            onClick={handleActivateClick}
          >
            {t('pageProperties.actions.reactivate')}
          </MenuItem>
        )}
        <MenuItem divider />
        {canCreateProperties && (
          <MenuItem
            data-testid="property-actions-menu-item"
            onClick={handleDeleteClick}
          >
            {t('pageProperties.actions.delete')}
          </MenuItem>
        )}
      </PropertyActionsDropdownButtonStyled>
    </div>
  );
};

export default PropertyActions;
