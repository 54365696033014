import { useContext } from 'react';
import TextField from 'components/forms/textField/TextField';
import {
  CustomDataFieldType,
  CustomDataFieldUsage,
} from 'models/CustomDataField';
import LeadModalContext from '../../LeadModalContext';
import { LeadDataFormRow } from './LeadModalDataTab.styles';

const LeadCustomData = () => {
  const { lead } = useContext(LeadModalContext);

  const { customData: leadCustomData } = lead || { customData: [] };

  if (!leadCustomData) {
    return null;
  }

  return (
    <>
      {leadCustomData.map((customData, i) => {
        if (customData.usage === CustomDataFieldUsage.CUSTOM_VARIABLES) {
          return (
            <LeadDataFormRow key={customData.uid}>
              <TextField
                label={customData.name}
                name={
                  customData.type === CustomDataFieldType.LONG_TEXT
                    ? `customData.${i}.value.longText`
                    : `customData.${i}.value.text`
                }
                colSmInput={5}
                colSmLabel={3}
                row={customData.type === CustomDataFieldType.LONG_TEXT ? 3 : 1}
                componentClass={
                  customData.type === CustomDataFieldType.LONG_TEXT
                    ? 'textarea'
                    : 'input'
                }
                maxLength={
                  customData.type === CustomDataFieldType.TEXT ? 255 : ''
                }
              />
            </LeadDataFormRow>
          );
        }

        return null;
      })}
    </>
  );
};

export default LeadCustomData;
