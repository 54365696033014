import useAppModal from 'hooks/useAppModal';
import { CalendarLead } from 'pages/stackedCalendar/Calendar.types';
import { Property } from 'models/Properties';
import LeadsOverlapModal, { modalId } from './LeadsOverlapModal';

const useLeadsOverlapModal = () => {
  const { openModal } = useAppModal();

  const openLeadsOverlapModal = (
    leads: CalendarLead[],
    allProperties: Property[],
  ) => {
    openModal({
      id: modalId,
      customContent: (
        <LeadsOverlapModal leads={leads} allProperties={allProperties} />
      ),
    });
  };

  return { openLeadsOverlapModal };
};

export default useLeadsOverlapModal;
