import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

export const Container = styled.div.attrs({
  'data-testid': 'pricing-modal',
})``;

export const PropertyPricingModalTitle = styled(Modal.Title).attrs({
  'data-testid': 'pricing-modal-header',
})`
  text-align: center;
`;

export const PropertyPricingWrapper = styled.div`
  .control-label {
    text-align: right;
  }
`;

export const PropertyPricingModalRestrictionsStyled = styled.div`
  display: flex;
  flex-direction: row;

  .restrictions-container {
    width: 50%;
  }
`;
