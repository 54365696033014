import { useContext, useEffect, useState } from 'react';
import useAppQuery from 'hooks/useAppQuery';
import CalendarContext from 'pages/stackedCalendar/CalendarContext';
import API from 'services/API';
import useAppUser from 'hooks/useAppUser';
import { Lead, LeadStatus } from 'models/Leads';
import {
  addDays,
  differenceInCalendarDays,
  endOfDay,
  format,
  setHours,
  startOfDay,
} from 'date-fns';
import { isBetween } from 'utils/dateTimeUtils';
import { PropertyBusinessType } from 'models/Properties';
import CalendarBodyContext from 'pages/calendar/body/CalendarBodyContext';
import StandaloneCalendarContext from 'pages/calendar/CalendarContext';
import {
  getLeadCheckInDate,
  getLeadCheckOutDate,
  isFromICS,
} from '../../utils/lead/leadUtils';
import useCalendarBodyRefreshRequest from '../stackedCalendar/useCalendarBodyRefreshRequest';
import { getUnloadedDatesForLeads } from './Calendar.utils';
import { StandaloneCalendarDataFetchParams } from './Calendar.types';

const useLeadsData = () => {
  const { daysCalendar, setDaysCalendar } = useContext(CalendarBodyContext);
  const { calendarFilter } = useContext(StandaloneCalendarContext);
  const { leadsMap, selectedProperty, propertiesMap, daysLeadsMap } =
    useContext(CalendarContext);
  const bodyRefreshRequest = useCalendarBodyRefreshRequest();
  const { agencyUid, isOwner } = useAppUser();
  const [fetchParams, setFetchParams] =
    useState<StandaloneCalendarDataFetchParams>();

  const { data: leads, isLoading: isLeadLoading } = useAppQuery(
    ['getLeads', selectedProperty, agencyUid, fetchParams],
    async () => {
      const { start, end } = fetchParams;

      const bookingValueField = isOwner
        ? 'ownerCommission{amount,currency}'
        : 'order{currency,totalAmount}';

      const property = propertiesMap[selectedProperty];
      const isUnit = property.businessType === PropertyBusinessType.UNIT;
      const propertyToQuery = isUnit ? property.unitTypeUid : selectedProperty;

      if (start && end) {
        const graphqlQuery = {
          operationName: '',
          query: `{leads (
              agencyUid: "${agencyUid}",
              propertyUids: ["${propertyToQuery}"],
              checkin_to: "${format(end, 'yyyy-MM-dd')}",
              checkout_from: "${format(start, 'yyyy-MM-dd')}",
              statuses: [BLOCKED,PENDING,ON_HOLD,BOOKED_EXTERNALLY,BOOKED_BY_AGENT,BOOKED_BY_CUSTOMER,BOOKED_BY_OWNER,STAY,ARCHIVED,PAID_IN_FULL,SAMPLE]
              ,limit:"2000"
          ){
            uid,
            firstName,
            lastName,
            email,
            source,
            checkInDateTimeString,
            checkOutDateTimeString,
            checkInLocalDateTime,
            checkOutLocalDateTime,
            childrenCount,
            adultCount,
            petCount,
            infantCount,
            status,
            ${bookingValueField},
            property{uid, mainPicture{tinyThumbnail}},
            unitUid,
            creator{type}
          }}`,
          variables: {},
        };

        const response = await API.post<any>('/v3/graphql', graphqlQuery);
        return response.data.data.leads;
      }
      return null;
    },
    { enabled: !!fetchParams },
  );

  useEffect(() => {
    if (!leads) return;
    // Reorder leads PMPK-4673
    leads
      .sort((a: Lead, b: Lead) => {
        if (isFromICS(a.source)) return 1;
        if (isFromICS(b.source)) return -1;
        return 0;
      })
      .sort((a, b) => {
        return differenceInCalendarDays(a.checkIn, b.checkIn);
      });

    // Leads are loaded, let's prepare lead data in Calendar day
    daysCalendar[selectedProperty]?.daysCalendar.map((d) => {
      if (isBetween(d.date, calendarFilter.start, calendarFilter.end)) {
        d.leads = [];
      }

      return d;
    });

    for (let l = 0; l < leads.length; l += 1) {
      const lead = leads[l];

      if (!leadsMap[lead.uid]) {
        leadsMap[lead.uid] = lead;
      }

      // Set all leads to the same time, to avoid third party agency lead to have 00:00:00 PMPK-4610
      const checkIn = setHours(getLeadCheckInDate(lead), 11);
      const checkOut = setHours(getLeadCheckOutDate(lead), 3);

      if (
        (!lead.unitUid && lead.property.uid === selectedProperty) ||
        (lead.unitUid && lead.unitUid === selectedProperty)
      ) {
        for (
          let tmp = checkIn;
          differenceInCalendarDays(tmp, checkOut) <= 0;
          tmp = addDays(tmp, 1)
        ) {
          daysCalendar[selectedProperty]?.daysCalendar.map((d) => {
            if (isBetween(tmp, startOfDay(d.date), endOfDay(d.date))) {
              // Prioritize booking on the display
              if (lead.status !== LeadStatus.BLOCKED) {
                d.leads.push(lead);
              } else {
                d.leads.unshift(lead);
              }

              if (daysLeadsMap.current[format(d.date, 'yyyy-MM-dd')]) {
                daysLeadsMap.current[format(d.date, 'yyyy-MM-dd')].push({
                  uid: lead.uid,
                  checkIn,
                  checkOut,
                });
              } else {
                daysLeadsMap.current[format(d.date, 'yyyy-MM-dd')] = [
                  {
                    uid: lead.uid,
                    checkIn,
                    checkOut,
                  },
                ];
              }
            }

            return d;
          });
        }
      }
    }
    setDaysCalendar((prevState) => ({
      ...prevState,
      [selectedProperty]: {
        daysCalendar: daysCalendar[selectedProperty]?.daysCalendar,
      },
    }));
  }, [leads]);

  useEffect(() => {
    setFetchParams(
      getUnloadedDatesForLeads(daysCalendar[selectedProperty]?.daysCalendar),
    );
  }, [daysCalendar[selectedProperty]?.daysCalendar?.length]);

  useEffect(() => {
    if (bodyRefreshRequest) {
      const { properties } = bodyRefreshRequest;

      const unitTypeFromUnit =
        propertiesMap[selectedProperty].unitTypeUid === properties[0];
      if (!properties.includes(selectedProperty) && !unitTypeFromUnit) {
        return;
      }

      setFetchParams({
        ...getUnloadedDatesForLeads(
          daysCalendar[selectedProperty]?.daysCalendar,
        ),
        requestId: new Date().getTime(),
      });
    }
  }, [bodyRefreshRequest]);

  return {
    isLoading: isLeadLoading,
  };
};

export default useLeadsData;
