/* eslint-disable jsx-a11y/label-has-associated-control */
import { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LeadModalContext from '../../LeadModalContext';
import { CustomDataFieldUsage } from '../../../../../../models/CustomDataField';

const GuestInformation = () => {
  const { t } = useTranslation();
  const { lead } = useContext(LeadModalContext);

  return (
    <Row className="guest-information">
      <Col sm={12}>
        {lead?.extraGuests && lead?.extraGuests.length > 0 && (
          <>
            <p>
              <strong>
                {t('componentLead.modal.stayDetailsTab.guestInfo.extraGuest')}:
              </strong>
            </p>

            {lead.extraGuests.map((extraGuest) => (
              <Row key={encodeURI(extraGuest.email)}>
                <Col xs={6}>
                  {`${extraGuest.lastName || ''} ${extraGuest.firstName || ''}`}
                </Col>
                <Col xs={6} className="text-right">
                  {extraGuest.age !== null &&
                    t(
                      'componentLead.modal.stayDetailsTab.guestInfo.ageYearsOld',
                      {
                        age: extraGuest.age,
                      },
                    )}
                </Col>
                <Col xs={12}>{extraGuest.email}</Col>
              </Row>
            ))}
            <br />
          </>
        )}

        <p>
          <strong>
            {t('componentLead.modal.stayDetailsTab.guestInfo.guestInformation')}
            :
          </strong>
        </p>

        <p>
          <label>
            {t('componentLead.modal.stayDetailsTab.guestInfo.willHaveVisit')}{' '}
            <strong>{lead?.willHaveVisit ? 'Yes' : 'No'}</strong>
          </label>
        </p>
        <p>
          <label>
            {t('componentLead.modal.stayDetailsTab.guestInfo.willHaveParty')}{' '}
            <strong>{lead?.willHaveParty ? 'Yes' : 'No'}</strong>
          </label>
        </p>

        {lead?.passportID && (
          <p>
            <label>
              {t(
                'componentLead.modal.stayDetailsTab.guestInfo.governmentIDNumber',
              )}
              : <strong>{lead.passportID}</strong>
            </label>
          </p>
        )}

        {lead?.passportCountryCode && (
          <p>
            <label>
              {t(
                'componentLead.modal.stayDetailsTab.guestInfo.governmentIDCountry',
              )}
              : <strong>{lead.passportCountryCode}</strong>
            </label>
          </p>
        )}

        {lead?.phoneNumber && (
          <p>
            <label>
              {t('componentLead.modal.stayDetailsTab.guestInfo.phoneNumber')}:{' '}
              <strong>{lead.phoneNumber}</strong>
            </label>
          </p>
        )}

        {lead?.guest && lead?.guest.identificationDocumentUrl && (
          <p>
            <label>
              {t(
                'componentLead.modal.stayDetailsTab.guestInfo.identificationDocumentURL',
              )}
              :{' '}
              <a
                target="_blank"
                href={lead.guest.identificationDocumentUrl}
                rel="noreferrer"
              >
                {t('componentLead.modal.stayDetailsTab.guestInfo.open')}
              </a>
            </label>
          </p>
        )}

        {lead?.customData &&
          lead?.customData
            .filter((cd) => cd.usage === CustomDataFieldUsage.PRE_ARRIVAL_FORM)
            .map((cd) => {
              return (
                <p key={cd.uid}>
                  <label>
                    {cd.name} :{' '}
                    <strong>
                      {cd.type === 'TEXT' ? cd.value.text : cd.value.longText}
                    </strong>
                  </label>
                </p>
              );
            })}
      </Col>
    </Row>
  );
};

export default GuestInformation;
