import { createElement, useContext, useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { LeadSource, LeadStatus } from 'models/Leads';
import { useFormState, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { normalizeKey } from 'utils/localeUtils';
import useAppUser from 'hooks/useAppUser';
import LeadModalInternalGuestNotesTab from './tabs/internalGuestNotes/LeadModaIInternalGuestNotesTab';
import LeadModalDetailsTab from './tabs/details/LeadModalDetailsTab';
import LeadModalClientInfoTab from './tabs/clientInfo/LeadModalClientInfoTab';
import LeadModalPaymentTimelineTab from './tabs/paymentTimeline/LeadModalPaymentTimelineTab';
import LeadModalStayDetailsTab from './tabs/stayDetailsTab/LeadModalStayDetailsTab';
import LeadModalDataTab from './tabs/dataTab/LeadModalDataTab';
import { LeadRebookParagraph } from './LeadModal.styles';
import useLeadRebook from './useLeadRebook';
import LeadModalContext from './LeadModalContext';
import { LeadModalActionsTab } from './tabs/actionsTab/LeadModalActionsTab';

export const LeadModalTabs = {
  leadDetails: 'leadDetails',
  clientInfo: 'clientInfo',
  paymentTimeline: 'paymentTimeline',
  stayDetails: 'stayDetails',
  data: 'data',
  internalGuestNotes: 'internalGuestNotes',
  actions: 'actions',
};

const newLeadTabs = [
  LeadModalTabs.leadDetails,
  LeadModalTabs.clientInfo,
  LeadModalTabs.stayDetails,
];

const tabNameToComponentMapping = {
  [LeadModalTabs.clientInfo]: LeadModalClientInfoTab,
  [LeadModalTabs.data]: LeadModalDataTab,
  [LeadModalTabs.internalGuestNotes]: LeadModalInternalGuestNotesTab,
  [LeadModalTabs.leadDetails]: LeadModalDetailsTab,
  [LeadModalTabs.paymentTimeline]: LeadModalPaymentTimelineTab,
  [LeadModalTabs.stayDetails]: LeadModalStayDetailsTab,
  [LeadModalTabs.actions]: LeadModalActionsTab,
};

const LeadModalFormBodyTabs = () => {
  const { lead } = useContext(LeadModalContext);
  const [activeTab, setActiveTab] = useState(LeadModalTabs.leadDetails);
  const { t } = useTranslation();
  const updateMode = !!useWatch({ name: 'uid' });
  const { errors } = useFormState();
  const { isRebookActionAvailable, prepareLeadRebookForm } = useLeadRebook();
  const { isEmployeeManager } = useAppUser();
  useEffect(() => {
    const { checkInDate, checkOutDate, email } = errors;

    if (checkInDate || checkOutDate) {
      setActiveTab(LeadModalTabs.leadDetails);
    } else if (email) {
      setActiveTab(LeadModalTabs.clientInfo);
    }
  }, [errors]);

  const getTabNamesAvailableForLead = () => {
    if (
      lead?.source !== LeadSource.DIRECT_BOOKINGDOTCOM ||
      !isEmployeeManager
    ) {
      return Object.values(LeadModalTabs).filter((key) => key !== 'actions');
    }
    return Object.values(LeadModalTabs);
  };

  const tabsToRender = updateMode ? getTabNamesAvailableForLead() : newLeadTabs;
  const showRebookButton = isRebookActionAvailable && updateMode;

  const isLeadPending = lead?.status === LeadStatus.PENDING;

  return (
    <>
      {showRebookButton && (
        <LeadRebookParagraph onClick={prepareLeadRebookForm}>
          {t('componentLead.modal.rebook')}
        </LeadRebookParagraph>
      )}
      <Tabs
        activeKey={activeTab}
        animation={false}
        onSelect={(tabName) => setActiveTab(tabName)}
      >
        {tabsToRender.map((tabName) => (
          <Tab
            data-testid={`lead-form-tab-${tabName}`}
            eventKey={tabName}
            key={tabName}
            title={t(normalizeKey(`componentLead.modal.tabNames.${tabName}`))}
          >
            <fieldset disabled={isLeadPending}>
              {createElement(tabNameToComponentMapping[tabName])}
            </fieldset>
          </Tab>
        ))}
      </Tabs>
    </>
  );
};

export default LeadModalFormBodyTabs;
