import { useFormContext } from 'react-hook-form';
import { FormControl } from 'react-bootstrap';
import {
  FieldRendererProps,
  GenericFormFieldProps,
} from '../genericFormField/GenericFormField.types';
import GenericFormField from '../genericFormField/GenericFormField';

interface Props extends Omit<GenericFormFieldProps, 'fieldRenderer'> {
  maxCharacters?: number;
  trimOnBlur?: boolean;
}

const TextAreaField: React.FC<Props> = ({
  name,
  id = name,
  maxCharacters,
  trimOnBlur,
  ...props
}: Props) => {
  const { setValue } = useFormContext();
  const fieldRenderer = ({
    field: { value, ref, onChange, onBlur },
  }: FieldRendererProps) => {
    return (
      <FormControl
        id={id}
        componentClass="textarea"
        name={name}
        data-testid={id}
        value={value}
        onChange={onChange}
        onBlur={() => {
          onBlur();
          if (trimOnBlur) setValue(name, value.trim());
        }}
        inputRef={ref}
        {...props}
      />
    );
  };

  return (
    <GenericFormField name={name} fieldRenderer={fieldRenderer} {...props} />
  );
};

TextAreaField.defaultProps = {
  maxCharacters: undefined,
  trimOnBlur: false,
};

export default TextAreaField;
