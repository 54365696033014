import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';

export const SubCategoryRow = styled(Row)`
  margin-left: 20px;

  .control-label {
    visibility: hidden;
  }

  .radio {
    margin-top: 0px;
    margin-bottom: 8px;
  }
`;

export const RadioRow = styled(Row)`
  .radio {
    margin-top: 0px;
    margin-bottom: 8px;
  }
`;

export const TextAreFieldWrapper = styled.div`
  textarea {
    resize: none;
  }
`;

export const RightAlignedCol = styled(Col)`
  display: flex;
  flex-direction: row-reverse;
`;
