import { Col } from 'react-bootstrap';
import styled, { css } from 'styled-components';

export const LeadModalFooterDeleteButtonContainer = styled(Col).attrs({
  md: 2,
})`
  text-align: left;
`;

export const LeadModalFooterMessagesContainer = styled(Col).attrs({
  md: 5,
})`
  text-align: left;
`;

const tooltipWrappedButtonsStyles = css`
  .btn + .tooltip-container > .btn,
  .tooltip-container + .tooltip-container > .btn {
    margin-left: 5px;
  }
`;

export const LeadModalFooterSubmitButtonsContainer = styled(Col).attrs({
  md: 5,
})`
  text-align: right;

  ${tooltipWrappedButtonsStyles}
`;

export const LeadModalFooterErrorMessage = styled.p.attrs({
  className: 'text-danger',
  'data-testid': 'lead-modal-footer-error-message',
})`
  .rebook-button {
    padding: 0;
    vertical-align: baseline;
  }
`;

export const LeadModalFooterActionsContainer = styled(Col).attrs({
  className: 'lead-modal-footer-actions-container',
  xs: 12,
})`
  justify-content: end;

  display: flex;

  ${tooltipWrappedButtonsStyles}
`;

export const LeadModalFooterAirbnbDocsMessage = styled.div`
  margin-right: auto;
  text-align: left;
`;

export const LeadModalFooterOverlappingLink = styled.span`
  color: #337ab7;
  text-decoration: none;
  display: inline;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
