import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const Container = styled.div``;

export const ContentWrapper = styled.div`
  max-width: 1180px;
  margin: 0 auto;
  padding: 20px;
`;

export const MobileLogo = styled.img`
  display: none;

  ${mediaQuery.md} {
    display: block;
  }
`;

export const DesktopLogo = styled.img`
  ${mediaQuery.md} {
    display: none;
  }
`;

export const Title = styled.h1`
  color: #2d2aa5;
  font-weight: bold;
  font-size: 24px;
  max-width: 856px;
  margin-top: 20px;
`;

export const TurnoWarning = styled.p`
  color: #2d2aa5;
  font-weight: 400;
  text-align: center;
  margin: 50px 0px 150px 0px;
`;
