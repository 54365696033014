import { trimAndLimitCharactersNumber } from 'utils/stringUtils';
import {
  computeBestProfile,
  getLeadCheckInDate,
  getLeadCheckOutDate,
} from 'utils/lead/leadUtils';
import { useTranslation } from 'react-i18next';
import {
  LeadPropertyPicture,
  LeadSearchDetails,
  LeadSearchOption,
} from './LeadSearchField.styles';
import { LeadsSearchResponseItem } from './LeadSearchField.types';

const LeadSelectOption = ({ option }: { option: LeadsSearchResponseItem }) => {
  const { t } = useTranslation();

  return (
    <LeadSearchOption data-testid={`found-lead-${option.uid}`}>
      <LeadPropertyPicture alt="" src={option.propertyPicture} />
      <LeadSearchDetails>
        <div>{computeBestProfile(option)}</div>
        <div className="text-muted">
          {trimAndLimitCharactersNumber({ text: option.email, limit: 25 })}
        </div>
        <div className="text-muted">
          {t('common.dateShort', { date: getLeadCheckInDate(option) })} -{' '}
          {t('common.dateShort', { date: getLeadCheckOutDate(option) })}
        </div>
      </LeadSearchDetails>
    </LeadSearchOption>
  );
};

export default LeadSelectOption;
