import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

export const LeadModalActionsTabColumn = styled(Col)`
  padding-top: 25px;
`;

export const ActionsTitle = styled.p`
  font-size: 16px;
  font-weight: bold;
`;

export const ModalRow = styled(Row)`
  padding-bottom: 16px;
`;

export const TypeTd = styled.td`
  min-width: 97px;
`;
