import { isSameDay } from 'date-fns';
import {
  CalendarLead,
  StackedCalendarMode,
} from 'pages/stackedCalendar/Calendar.types';
import {
  getColorSource,
  getLeadCheckInDate,
  getLeadCheckOutDate,
  getOpacity,
  getZIndexFromLeadSource,
} from 'utils/lead/leadUtils';
import { classNames } from 'utils/classNameUtils';
import LeadViewRenderer from '../leads/LeadViewRenderer';
import { DayCellLeadContainer } from './StackedCalendarDays.styles';

const DayCellLead = ({
  width,
  dayDate,
  lead,
  mode,
}: {
  width: number;
  dayDate: Date;
  lead: CalendarLead;
  mode: StackedCalendarMode;
}) => {
  const isCheckInDay = isSameDay(getLeadCheckInDate(lead), dayDate);
  const isCheckOutDay = isSameDay(getLeadCheckOutDate(lead), dayDate);

  const opacity = getOpacity(lead.status);
  const color = getColorSource(mode, lead);
  const zIndex = getZIndexFromLeadSource(lead.source, lead.status);

  return (
    <>
      <DayCellLeadContainer
        // @ts-expect-error
        className={classNames({
          'check-in': isCheckInDay,
          'check-out': isCheckOutDay,
        })}
        isCheckInDay={isCheckInDay}
        isCheckOutDay={isCheckOutDay}
        $background={color}
        $opacity={opacity}
        $width={width}
        $zIndex={zIndex}
        data-lead-uid={lead.uid}
      />
      <LeadViewRenderer
        key={`${lead.uid}-view-renderer`}
        dayDate={dayDate}
        isCheckInDay={isCheckInDay}
        lead={lead}
      />
    </>
  );
};

export default DayCellLead;
