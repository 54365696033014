import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import LeadSearchField from 'components/forms/domain/lead/LeadSearchField';
import PipelineContext from '../PipelineContext';

const PipelineLeadFilter = () => {
  const { t } = useTranslation();
  const { leadSearchFieldRef, properties } = useContext(PipelineContext);

  return (
    <LeadSearchField
      align="right"
      name="lead"
      colSmInput={12}
      placeholder={t('form.guestNameEmail')}
      instanceRef={leadSearchFieldRef}
      preFetchedProperties={properties}
    />
  );
};

export default PipelineLeadFilter;
