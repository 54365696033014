import BookingDotComAvailableActions from 'models/ChannelActions';
import useAppQuery from 'hooks/useAppQuery';
import API from 'services/API';

const useFetchAvailableBookingDotComActions = (leadUid: string) => {
  const { data: bookingDotComActions, isInitialLoading: isFetching } =
    useAppQuery(
      ['available-bookingdotcom-actions', leadUid],
      async (): Promise<BookingDotComAvailableActions> => {
        const response = await API.get<BookingDotComAvailableActions>(
          `api/internal/leads/channel-actions/bookingdotcom/${leadUid}/available-actions`,
        );
        return response.data;
      },
      {
        enabled: !!leadUid,
      },
    );
  return { isFetching, bookingDotComActions };
};

export default useFetchAvailableBookingDotComActions;
