import { AxiosResponse } from 'axios';
import API from 'services/API';
import useAppMutation from 'hooks/useAppMutation';
import { LeadsSearchResponse } from './LeadSearchField.types';

const searchLeads = async ({ query }: { query: string }) => {
  const response: AxiosResponse<LeadsSearchResponse> = await API.get(
    `/api/internal/leads/search?query=${encodeURIComponent(query)}`,
  );

  return response.data.leads || [];
};

const useLeadSearchMutation = () => {
  return useAppMutation({
    mutationFn: searchLeads,
  });
};

export default useLeadSearchMutation;
