import { useContext } from 'react';
import { useFormState, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getUnitType } from 'utils/lead/leadUtils';
import useCheckPropertyAvailability from '../../../property/useCheckPropertyAvailability';
import LeadModalContext from '../LeadModalContext';

const useLeadModalPropertyAvailability = () => {
  const { dirtyFields } = useFormState();
  const { t } = useTranslation();
  const { availableProperties } = useContext(LeadModalContext);
  const { checkInDate, checkOutDate, propertyUid, uid: leadUid } = useWatch();

  const unitType = getUnitType(availableProperties, propertyUid);

  const { propertyAvailability, error: propertyAvailabilityError } =
    useCheckPropertyAvailability({
      enabled:
        !!dirtyFields.checkInDate ||
        !!dirtyFields.checkOutDate ||
        !!dirtyFields.propertyUid, // prevents checking on modal's load
      propertyUid: unitType?.uid || propertyUid,
      startDate: checkInDate,
      endDate: checkOutDate,
      leadUidsToExclude: leadUid ? [leadUid] : [],
    });
  const isUnavailable = propertyAvailability && !propertyAvailability.available;

  return {
    isUnavailable,
    overlappingLeads: propertyAvailability?.blockingLeadUids,
    errorMessage:
      (isUnavailable &&
        t('componentLead.modal.form.validation.propertyUnavailable')) ||
      propertyAvailabilityError,
  };
};

export default useLeadModalPropertyAvailability;
