import { format } from 'date-fns';

export function getDayCellDataKey(dayDate: Date) {
  return format(dayDate, 'yyyy-MM-dd');
}

export const propertyCalendarGraphQLQuery = `
    uid,
    name,
    isActive,
    mainPicture{tinyThumbnail, largeThumbnail},
    picture,
    businessType,
    propertyType,
    masterUnitUid,
    availability{maxGuests, checkInTimeStart, checkOutTime},
    subUnits{
      uid,
      name,
      mainPicture{tinyThumbnail, largeThumbnail},
      availability{maxGuests}, 
      businessType,
      propertyType,
      masterUnitUid,
      isActive,
    },
    numberOfSubUnits,
    unitTypes{
      uid,
      name,
      mainPicture{tinyThumbnail},
      availability{maxGuests},
      businessType,
      hotelUid,
      units{uid, name, isActive},
      isActive,
    },
    pricing{currency},
  `;

export const propertyCalendarConditionGraphQLQuery = `offset:"0",limit:"1000",topLevelOnly:true,returnTopLevelUnitsWithMatchingSubLevels:true,sortStrategy:"SORT_BY_NAME"`;
