import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MisconductCategory } from '../../../../../../../models/channelactions/GuestMisconductBody';
import { RadioField } from '../../../../../../forms/radioField/RadioField';
import { useMisconductCategories } from './useMisconductCategories';
import { RadioRow, SubCategoryRow } from './GuestMisconductModal.styles';

export const CategoryList = () => {
  const { categories, translateSubCategoryName, translateCategoryName } =
    useMisconductCategories();
  const categoryValue: string = useWatch({ name: 'category' });
  const { t } = useTranslation();
  const categoryLabel = t(
    'componentLead.modal.actionsTab.guestMisconduct.fieldCategory',
  );

  function isCurrentCategory(cat: MisconductCategory) {
    return categoryValue === cat.id.toString();
  }

  function renderSubCategories(cat: MisconductCategory) {
    if (cat?.subcategories?.length <= 0 || !isCurrentCategory(cat)) {
      return null;
    }

    return (
      <>
        {cat.subcategories.map((subCat) => {
          return (
            <SubCategoryRow key={subCat.id}>
              <RadioField
                name="subCategory"
                label={translateSubCategoryName(subCat)}
                value={subCat.id}
                groupLabel={' '}
                colSmInput={7}
                colSmLabel={4}
                required
              />
            </SubCategoryRow>
          );
        })}
      </>
    );
  }

  function renderCategories() {
    return categories.map((cat, index) => {
      return (
        <>
          <RadioRow>
            <RadioField
              colSmInput={7}
              colSmLabel={4}
              name="category"
              label={translateCategoryName(cat)}
              value={cat.id}
              required={index === 0}
              groupLabel={index === 0 ? categoryLabel : ' '}
            />
          </RadioRow>
          {renderSubCategories(cat)}
        </>
      );
    });
  }

  return <>{renderCategories()}</>;
};
