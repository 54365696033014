import { ReactNode } from 'react';
import ReactSelect, {
  components,
  OptionProps,
  Props as ReactSelectProps,
} from 'react-select';
import GenericFormField from '../genericFormField/GenericFormField';
import { GenericFormFieldBaseProps } from '../genericFormField/GenericFormField.types';

interface OptionBase {
  label: string | ReactNode;
  value: string;
}

type ReactCustomSelectProps = ReactSelectProps<OptionBase>;

interface CustomSelectFieldProps
  extends Omit<GenericFormFieldBaseProps, 'fieldRenderer' | 'placeholder'> {
  formatOptionLabel?: ReactCustomSelectProps['formatOptionLabel'];
  isSearchable?: boolean;
  name: string;
  optionRenderer?: (props: OptionProps<OptionBase>) => ReactNode;
  options: ReactCustomSelectProps['options'];
  placeholder?: ReactCustomSelectProps['placeholder'];
}

const { Option } = components;

function getCustomComponents(
  optionRenderer: CustomSelectFieldProps['optionRenderer'],
) {
  if (!optionRenderer) {
    return undefined;
  }

  return {
    Option: (props: OptionProps<OptionBase>) => (
      <Option {...props}>{optionRenderer(props)}</Option>
    ),
  };
}

const CustomSelectField = ({
  disabled,
  formatOptionLabel,
  isSearchable,
  name,
  optionRenderer,
  options,
  placeholder,
  ...rest
}: CustomSelectFieldProps) => {
  const fieldRenderer = ({ field: { onChange, value: currentValue } }) => (
    <div>
      <ReactSelect
        options={options}
        onChange={({ value }: OptionBase) => {
          onChange(value); // this 'hack' is required to prevent setting the entire option as value
        }}
        // value needs to be provided as an option object in order to have selected value set properly
        value={
          currentValue && {
            label: options.find(
              ({ value }: OptionBase) => value === currentValue,
            )?.label,
            value: currentValue,
          }
        }
        className="custom-select"
        classNamePrefix="select"
        components={getCustomComponents(optionRenderer)}
        isDisabled={disabled}
        isSearchable={isSearchable}
        formatOptionLabel={formatOptionLabel}
        placeholder={placeholder}
      />
    </div>
  );

  return (
    <GenericFormField name={name} fieldRenderer={fieldRenderer} {...rest} />
  );
};

CustomSelectField.defaultProps = {
  formatOptionLabel: undefined,
  isSearchable: true,
  optionRenderer: undefined,
  placeholder: undefined,
};

export default CustomSelectField;
