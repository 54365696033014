import styled from 'styled-components';
import mediaQuery from 'styles/mediaQuery';

export const Container = styled.div`
  margin-top: 40px;
  border-radius: 10px;
  box-shadow: 10px 15px 25px 0px rgba(0, 0, 0, 0.15);
  padding: 20px;

  h3 {
    font-weight: 300;
  }

  p {
    margin-top: 10px;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
  margin: 10px 0;
`;

export const CleanerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 27px;
  gap: 10px;

  ${mediaQuery.md} {
    flex-wrap: wrap;
    justify-content: center;
  }

  ${mediaQuery.sm} {
    flex-direction: column;
  }
`;

export const Cleaner = styled.div`
  display: flex;
  gap: 10px;
  width: 352px;
  height: 134px;
  padding: 17px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

export const ProfilePicture = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 10px;
  object-fit: cover;
`;

export const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  h5 {
    font-weight: bold;
    font-size: 16px;
  }

  p {
    font-weight: 400;
    font-size: 16px;
  }

  span,
  svg {
    color: #31b48d;
    font-weight: bold;
  }

  & > div {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;

export const ContainerFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  opacity: 0.7;
`;
