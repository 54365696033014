/* eslint-disable no-nested-ternary */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isValid, parseISO } from 'date-fns';
import { Property, PropertyBusinessType } from 'models/Properties';
import useSearchURLParams, { URLParamsName } from 'hooks/useSearchURLParams';
import useAppUser from 'hooks/useAppUser';
import CalendarContext from './CalendarContext';
import {
  CalendarFilter,
  JobsMap,
  LeadsMap,
  PropertiesMap,
  StackedCalendarMode,
} from './Calendar.types';
import {
  calendarModeLocalStorageKey,
  getCalendarFilterLocalStorageKey,
  stackedCalendarPropertiesConstants,
} from './Calendar.constants';

export const startDateOffsetByMode = {
  [StackedCalendarMode.BOOKING]: 45,
  [StackedCalendarMode.PRICING]: 30,
  [StackedCalendarMode.COMBINED]: 30,
};

function parseStoredDate(dateStr: string): Date | undefined {
  const parsedDate = parseISO(dateStr);
  return isValid(parsedDate) ? parsedDate : undefined;
}

const CalendarContextProvider = ({ children }) => {
  const { isOwner, userUid } = useAppUser();
  const storedFilter = JSON.parse(
    localStorage.getItem(getCalendarFilterLocalStorageKey(userUid)),
  );

  const initialFilter = storedFilter
    ? {
        ...storedFilter,
        availableFrom: parseStoredDate(storedFilter.availableFrom),
        availableTo: parseStoredDate(storedFilter.availableTo),
        offset: 0,
        limit: stackedCalendarPropertiesConstants.defaultLimit,
      }
    : ({
        offset: 0,
        limit: stackedCalendarPropertiesConstants.defaultLimit,
        status: 'active',
      } as CalendarFilter);

  const [bodyWidth, setBodyWidth] = useState<number>(0);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [allProperties, setAllProperties] = useState<Property[]>([]);
  const [propertiesMap] = useState<PropertiesMap>({});
  const persistedMode = localStorage.getItem(calendarModeLocalStorageKey);

  const initialMode = isOwner
    ? StackedCalendarMode.COMBINED
    : persistedMode
    ? StackedCalendarMode[persistedMode]
    : StackedCalendarMode.BOOKING;

  const [mode, setMode] = useState<StackedCalendarMode>(initialMode);
  const [totalPropertiesNumber, setTotalPropertiesNumber] =
    useState<number>(-1);
  const [filter, setFilter] = useState<CalendarFilter>(initialFilter);
  const { setParameter, deleteParameter, getParameter } = useSearchURLParams();
  const [selectedProperty, setSelectedProperty] = useState<string>(
    getParameter(URLParamsName.PROPERTY_UID),
  );

  const [leadsMap] = useState<LeadsMap>({});
  const [jobsMap] = useState<JobsMap>({});
  const daysLeadsMap = useRef<
    Record<
      string,
      {
        uid: string;
        checkIn: Date;
        checkOut: Date;
      }[]
    >
  >({});

  const updatePropertiesMap = useCallback(
    ({ properties }: { properties: Property[] }) => {
      const propertiesToAdd = properties.reduce(
        (propertiesAccumulator, property) => ({
          ...propertiesAccumulator,
          [property.uid]: property,
          ...(property.subUnits || []).reduce(
            (acc, subunit) => ({
              ...acc,
              [subunit.uid]: subunit,
            }),
            {},
          ),
          ...(property.unitTypes || []).reduce(
            (acc, unitType) => ({
              ...acc,
              [unitType.uid]: unitType,
              ...(unitType.units || []).reduce(
                (a, unit) => ({
                  ...a,
                  [unit.uid]: {
                    ...unit,
                    unitTypeUid: unitType.uid,
                    businessType: PropertyBusinessType.UNIT,
                  },
                }),
                {},
              ),
            }),
            {},
          ),
        }),
        {},
      );

      Object.assign(propertiesMap, propertiesToAdd);
    },
    [propertiesMap],
  );

  useEffect(() => {
    if (selectedProperty) {
      setParameter(URLParamsName.PROPERTY_UID, selectedProperty);
    } else {
      deleteParameter(URLParamsName.PROPERTY_UID);
    }
  }, [selectedProperty]);

  const contextValue = useMemo(
    () => ({
      allProperties,
      bodyWidth,
      filter,
      isFilterOpen,
      mode,
      propertiesMap,
      selectedProperty,
      totalPropertiesNumber,
      setBodyWidth,
      setFilter,
      setAllProperties,
      setIsFilterOpen,
      setMode,
      setSelectedProperty,
      setTotalPropertiesNumber,
      updatePropertiesMap,
      leadsMap,
      jobsMap,
      daysLeadsMap,
    }),
    [
      allProperties,
      bodyWidth,
      filter,
      isFilterOpen,
      mode,
      propertiesMap,
      selectedProperty,
      totalPropertiesNumber,
      leadsMap,
      jobsMap,
      daysLeadsMap,
    ],
  );

  return (
    <CalendarContext.Provider value={contextValue}>
      {children}
    </CalendarContext.Provider>
  );
};

export default CalendarContextProvider;
