import { useTranslation } from 'react-i18next';
import { normalizeKey } from '../../../../../../utils/localeUtils';

const roomReservationRegex =
  /Room reservation (\d+) not within right hotel reservation/;

export function cleanError(error: string) {
  try {
    if (error.match(roomReservationRegex)) {
      return 'RESERVATION_ALREADY_CANCELLED_OR_NOT_FOUND';
    }
    if (error.endsWith('.')) {
      return error.substring(0, error.length - 1);
    }
    return error;
  } catch (E) {
    return error;
  }
}

export const useReportingApiErrorTranslation = () => {
  const { t, i18n } = useTranslation();
  const translateError = (response: any) => {
    if (!response || !response?.apiErrorMessage) {
      return t('componentLead.modal.actionsTab.apiErrors.genericErrorMessage');
    }
    const key = `componentLead.modal.actionsTab.apiErrors.${cleanError(
      response.apiErrorMessage,
    )}`;
    if (i18n.exists(key)) {
      return t(normalizeKey(key));
    }
    return response.apiErrorMessage;
  };

  return { translateError };
};
