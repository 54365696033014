import { CheckboxOption } from './CheckboxGroupField';

/**
 * Extracts the checkbox options values from the given form data passed to this
 * function.
 */
export function extractCheckboxesValue(
  formValues: Record<string, unknown>,
  name: string,
  options: CheckboxOption[],
): CheckboxOption[] {
  const updatedOptions = options.map((option) => {
    const currentValue = formValues[`${name}Option${option.name}`];

    return {
      ...option,
      ...(currentValue !== undefined ? { value: currentValue } : {}),
    };
  });

  return updatedOptions as CheckboxOption[];
}
