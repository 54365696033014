import styled from 'styled-components';
import theme from 'styles/theme';
import { CollapseVariant } from './Collapse.types';

const VARIANT_STYLINGS = {
  border: {
    card: `1px solid ${theme.colors.gray300}`,
    unstyled: 'none',
  },
  borderRadius: {
    card: '4px',
    unstyled: 'none',
  },
  backgroundColorHeader: {
    card: theme.colors.light,
    unstyled: 'transparent',
  },
  marginHeader: {
    card: '0',
    unstyled: '10px 0',
  },
  paddingBody: {
    card: '16px 10px',
    unstyled: '0',
  },
  paddingHeader: {
    card: '0 10px',
    unstyled: '0',
  },
  paddingLabel: {
    card: '16px 0',
    unstyled: '10px 0',
  },
} satisfies { [key: string]: { [key in CollapseVariant]: string } };

export const CollapseStyled = styled.div<{
  $isExpanded: boolean;
  $variant: CollapseVariant;
}>`
  border: ${({ $variant }) => VARIANT_STYLINGS.border[$variant]};
  border-radius: ${({ $variant }) => VARIANT_STYLINGS.borderRadius[$variant]};

  .collapsible-header {
    background-color: ${({ $variant }) =>
      VARIANT_STYLINGS.backgroundColorHeader[$variant]};
    border-bottom: 1px solid ${theme.colors.gray300};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: ${({ $variant }) => VARIANT_STYLINGS.marginHeader[$variant]};
    padding: ${({ $variant }) => VARIANT_STYLINGS.paddingHeader[$variant]};

    .collapsible-header-label {
      flex: 1;
      font-weight: bold;
      padding: ${({ $variant }) => VARIANT_STYLINGS.paddingLabel[$variant]};
    }
  }

  .collapsible-body {
    padding: ${({ $variant }) => VARIANT_STYLINGS.paddingBody[$variant]};
  }
`;
