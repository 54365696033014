import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import CheckboxGroupField from 'components/forms/checkboxGroupField/CheckboxGroupField';
import { PropertyPricingModalRestrictionsStyled } from './PropertyPricingModal.styles';
import PropertyPricingModalContext from './PropertyPricingModalContext';

const PropertyPricingModalRestrictions = () => {
  const { t } = useTranslation();
  const { checkInCheckboxes, checkoutCheckboxes } = useContext(
    PropertyPricingModalContext,
  );

  return (
    <PropertyPricingModalRestrictionsStyled>
      <div
        className="restrictions-container"
        data-testid="check-in-restrictions"
      >
        <span>{t('componentProperty.modal.form.allowCheckInOn')}</span>
        <CheckboxGroupField
          allLabel={t('componentProperty.modal.form.allDays')}
          name="checkIn"
          options={checkInCheckboxes}
        />
      </div>
      <div
        className="restrictions-container"
        data-testid="checkout-restrictions"
      >
        <span>{t('componentProperty.modal.form.allowCheckoutOn')}</span>
        <CheckboxGroupField
          allLabel={t('componentProperty.modal.form.allDays')}
          name="checkout"
          options={checkoutCheckboxes}
        />
      </div>
    </PropertyPricingModalRestrictionsStyled>
  );
};

export default PropertyPricingModalRestrictions;
