export enum CustomDataFieldType {
  LONG_TEXT = 'LONG_TEXT',
  TEXT = 'TEXT',
}

export enum CustomDataFieldUsage {
  CUSTOM_VARIABLES = 'CUSTOM_VARIABLES',
  PRE_ARRIVAL_FORM = 'PRE_ARRIVAL_FORM',
}

export interface CustomDataField {
  agencyUid: string;
  uid: string;
  type: CustomDataFieldType;
  usage: CustomDataFieldUsage;
  name: string;
  variable: string;
}
