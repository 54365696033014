import { useTranslation } from 'react-i18next';
import Tooltip from 'components/tooltip/Tooltip';
import { isFromICS } from 'utils/lead/leadUtils';
import { Lead, LeadStatus } from 'models/Leads';
import { calendarStyleConstants } from 'pages/calendar/Calendar.constant';
import { normalizeKey } from '../../../utils/localeUtils';
import { WrapperEvent } from './LeadTooltip.styles';
import LeadTooltipBookingDetails from './LeadTooltipBookingDetails';

const LeadTooltip = ({
  lead,
  wrapperLeft,
  isOverlapping,
}: {
  lead: Lead;
  wrapperLeft?: number;
  isOverlapping?: boolean;
}) => {
  const { t } = useTranslation();
  const { creator, source, status } = lead;
  const { type: creatorType } = creator || {};

  const getOverlay = () => {
    return (
      <>
        {isFromICS(source) ? (
          <span
            style={{ color: `${calendarStyleConstants.leadLabelColor.ICS}` }}
          >
            {t('pageCalendar.tooltip.importedFromIcal')}
            <br />
          </span>
        ) : null}
        {status === LeadStatus.BLOCKED ? (
          <>
            {t(
              normalizeKey(
                `pageCalendar.tooltip.blocked${
                  creatorType ? `_${creatorType}` : ''
                }`,
              ),
            )}
          </>
        ) : (
          <LeadTooltipBookingDetails
            lead={lead}
            isOverlapping={isOverlapping}
          />
        )}
      </>
    );
  };

  return (
    <Tooltip id="tooltip" text={getOverlay()} placement="top">
      <WrapperEvent $lead={lead} $left={wrapperLeft} />
    </Tooltip>
  );
};

LeadTooltip.defaultProps = {
  wrapperLeft: 0,
  isOverlapping: false,
};

export default LeadTooltip;
